// Global
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { useTheme } from 'lib/context/ThemeContext';
import { ImageField, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
// Components
import Component from 'src/helpers/Component/Component';
import { CuratedImageDisplayTheme } from './CuratedImageDisplay.theme';
import { ComponentProps } from 'lib/types/component-props';
import { getEnum } from 'lib/utils';
import Headline from 'src/helpers/Headline/Headline';
import { BodyCopy } from 'src/helpers/BodyCopy';
import SingleButton from 'src/helpers/SingleButton/SingleButton';
import ImagePrimary from 'src/helpers/Media/ImagePrimary';
import { SliderWrapper } from 'src/helpers/SliderWrapper';
import { useCurrentScreenType, getBreakpoint } from 'lib/utils/get-screen-type';
import React, { useRef, useState } from 'react';
import ModalWrapper from 'src/helpers/ModalWrapper/ModalWrapper';
import { getPhotoItemProps } from 'src/helpers/PhotoItemWithDetail/PhotoItemWithDetail.Utils';
import PhotoItemWithDetail, {
  PhotoItemWithDetailProps,
} from 'src/helpers/PhotoItemWithDetail/PhotoItemWithDetail';
import SvgIcon from 'src/helpers/SvgIcon/SvgIcon';
import { Foundation } from 'src/.generated/Foundation.EnterpriseWeb.model';
import { SliderRefType, SliderType } from 'src/helpers/SliderWrapper/SliderWrapper';

export type CuratedImageDisplayProps =
  Feature.EnterpriseWeb.Enterprise.Components.General.CuratedImageDisplay.CuratedImageDisplay & {
    fields?: {
      images: Foundation.EnterpriseWeb.Enterprise.BaseTemplates.BasePhoto[];
    };
  } & ComponentProps;

type displayStyle = 'grid' | 'horizontal-scroll';

const CuratedImageDisplay = (props: CuratedImageDisplayProps) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isLightboxVisible, setIsLightboxVisible] = useState(false);

  const sliderRef = useRef<SliderType>();

  const sliderSettings = {
    initialSlide: props.fields?.images && props.fields?.images.length > 1 ? currentSlideIndex : 0,
    arrows: false,
    dots: false,
  };

  const openModal = (index: number) => {
    setIsLightboxVisible(true);
    setCurrentSlideIndex(index);
  };

  const { themeData } = useTheme(CuratedImageDisplayTheme);
  const { currentScreenWidth } = useCurrentScreenType();

  const DESKTOP_DISPLAY_STYLE = getEnum<displayStyle>(props.fields?.desktopDisplayStyle) || 'grid';
  const MOBILE_DISPLAY_STYLE = getEnum<displayStyle>(props.fields?.mobileDisplayStyle) || 'grid';

  const renderCuratedImages = (): JSX.Element => {
    if (currentScreenWidth < getBreakpoint('md')) {
      // Renderings for mobile devices
      switch (MOBILE_DISPLAY_STYLE) {
        case 'grid':
          return gridStyleImages();
        case 'horizontal-scroll':
          return scrollStyleImage();
      }
    } else {
      // Renderings for tablets and large screen devices
      switch (DESKTOP_DISPLAY_STYLE) {
        case 'grid':
          return gridStyleImages();
        case 'horizontal-scroll':
          return scrollStyleImage();
      }
    }
  };

  const gridStyleImages = (): JSX.Element => {
    return (
      <ul className="grid grid-cols-2 gap-y-m md:grid-cols-12 md:gap-s">
        {props.fields?.images.map(
          (
            ImageItem: Foundation.EnterpriseWeb.Enterprise.BaseTemplates.BasePhoto,
            index: number
          ) => {
            return (
              <li
                tabIndex={0}
                key={index}
                onClick={() => {
                  openModal(index);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    openModal(index);
                  }
                }}
                className="group relative col-span-12 cursor-pointer md:col-span-4 lg:col-span-3"
              >
                <ImagePrimary
                  imageLayout={'responsive'}
                  ratio="square"
                  fields={{
                    primaryImageCaption: {
                      value: '',
                    },
                    primaryImage: ImageItem.fields?.thumbnailImage as ImageField,
                    primaryImageMobile: ImageItem.fields?.thumbnailImage as ImageField,
                  }}
                />
                <SvgIcon
                  className="-translate-t-3/4 absolute top-1/2 left-1/2 -translate-x-1/2 rounded-full bg-black bg-opacity-[.65] p-l text-white opacity-0 transition-all ease-linear group-hover:-translate-y-1/2 group-hover:opacity-100"
                  icon="zoom-pinch"
                />
              </li>
            );
          }
        )}
      </ul>
    );
  };

  const scrollStyleImage = (): JSX.Element => {
    const sliderSettings = {
      dots: false,
      arrow: true,
      infinite: false,
      slidesToShow: 4,
      responsive: [
        {
          breakpoint: getBreakpoint('md'),
          settings: {
            slidesToShow: 1,
            dots: true,
          },
        },
        {
          breakpoint: getBreakpoint('lg'),
          settings: {
            slidesToShow: 3,
            dots: false,
          },
        },
      ],
    };

    return (
      <SliderWrapper sliderSettings={sliderSettings}>
        {props.fields?.images.map(
          (
            ImageItem: Foundation.EnterpriseWeb.Enterprise.BaseTemplates.BasePhoto,
            index: number
          ) => {
            return (
              <React.Fragment key={`ImageItem-${index}`}>
                <div
                  tabIndex={0}
                  onClick={() => {
                    openModal(index);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      openModal(index);
                    }
                  }}
                  className="group relative cursor-pointer md:mr-s"
                >
                  <ImagePrimary
                    imageLayout={'responsive'}
                    ratio="square"
                    fields={{
                      primaryImageCaption: {
                        value: '',
                      },
                      primaryImage: ImageItem.fields?.thumbnailImage as ImageField,
                      primaryImageMobile: ImageItem.fields?.thumbnailImage as ImageField,
                    }}
                  />
                  <SvgIcon
                    className="-translate-t-3/4 absolute top-1/2 left-1/2 -translate-x-1/2 rounded-full bg-black bg-opacity-[.65] p-l text-white opacity-0 transition-all ease-linear group-hover:-translate-y-1/2 group-hover:opacity-100"
                    icon="zoom-pinch"
                  />
                </div>
              </React.Fragment>
            );
          }
        )}
      </SliderWrapper>
    );
  };

  const renderPhotoItem = () => {
    return props.fields?.images?.map(
      (
        result: Foundation.EnterpriseWeb.Enterprise.BaseTemplates.BasePhoto &
          (
            | Feature.EnterpriseWeb.AndersenWindows.Data.Photos.Photo
            | Feature.EnterpriseWeb.RenewalByAndersen.Data.Photos.Photo
          ),
        index: number
      ) => {
        const photoObject = getPhotoItemProps(result, false) as PhotoItemWithDetailProps;
        if (props.fields?.hideRelatedPages?.value && photoObject?.fields) {
          photoObject.fields.relatedPages = [];
        }

        return <PhotoItemWithDetail key={index} {...photoObject} />;
      }
    );
  };

  return (
    <Component variant="lg" dataComponent="general/curatedimagedisplay" {...props}>
      <div className="col-span-12">
        <Headline {...props} classes={themeData.classes.headline} />
        <BodyCopy {...props} classes={themeData.classes.bodyCopy} />
        <SingleButton {...props} classes={themeData.classes.SingleButton} />
        {renderCuratedImages()}
        {/* Photo gallery */}
        {isLightboxVisible && (
          <ModalWrapper
            isModalOpen={isLightboxVisible}
            size="fluid"
            handleClose={() => setIsLightboxVisible(false)}
          >
            <div className="px-ml pb-ml pt-s">
              {props.fields?.images && props.fields?.images.length > 1 ? (
                <>
                  <SliderWrapper
                    sliderSettings={sliderSettings}
                    sliderRef={sliderRef as SliderRefType}
                  >
                    {renderPhotoItem()}
                  </SliderWrapper>
                  <div className="mt-m flex items-center justify-between text-xxs md:justify-center">
                    <div
                      role="button"
                      className="ml-xxxs flex cursor-pointer items-center text-button font-bold md:mr-xs"
                      onClick={() => sliderRef.current && sliderRef.current.slickPrev()}
                    >
                      <SvgIcon className="mr-xs" icon="arrow-left" />

                      <Text field={props.fields?.previousLabel} tag="span" />
                    </div>
                    <div
                      role="button"
                      className="mr-xxxs flex cursor-pointer items-center text-button font-bold md:ml-xs"
                      onClick={() => {
                        sliderRef.current && sliderRef.current.slickNext();
                      }}
                    >
                      <Text field={props.fields?.nextLabel} tag="span" />
                      <SvgIcon className="ml-xs" icon="arrow-right" />
                    </div>
                  </div>
                </>
              ) : (
                renderPhotoItem()
              )}
            </div>
          </ModalWrapper>
        )}
      </div>
    </Component>
  );
};

export default withDatasourceCheck()<CuratedImageDisplayProps>(CuratedImageDisplay);
