// Global
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import Head from 'next/head';
import { withDatasourceCheck, GetStaticComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
// Components
import Component from 'src/helpers/Component/Component';
import { ComponentProps } from 'lib/types/component-props';
import VideoListing from 'src/helpers/VideoGalleryHelpers/VideoListing';

import {
  getMediaPrimaryStaticProps,
  MediaPrimaryStaticPropsArray,
  VimeoVideoStaticProps,
} from 'src/helpers/Media';

import { SitecoreIds } from 'lib/constants';
import { guidEquals } from 'lib/utils/string-utils';
import { normalizeSitecoreDateStringFormattedWithTime } from 'lib/utils/string-utils';
import { Foundation } from 'src/.generated/Foundation.EnterpriseWeb.model';

export type VideoGalleryProps =
  Feature.EnterpriseWeb.RenewalByAndersen.Components.General.VideoGallery.VideoGallery &
    ComponentProps;
const VideoGallery = (props: VideoGalleryProps) => {
  // ld+Json Schema Script YouTube and Vimeo
  const videoGalleryContent = props.fields.videos.map((video: VideoGalleryProps, index: number) => {
    type StaticPropsItem = {
      videoId: string;
      mediaPrimary: VimeoVideoStaticProps;
    };
    let ldJsonScriptYouTube;
    let ldJsonScriptVimeo;
    if (
      guidEquals(
        video.fields?.primaryVideo?.templateId,
        SitecoreIds.Feature.Data.Elements.Videos.YouTubeVideo.TemplateId
      )
    ) {
      const formattedDate =
        video.fields?.primaryVideo?.fields?.lastUpdated?.value &&
        video.fields?.primaryVideo?.fields?.lastUpdated?.value !== '0001-01-01T00:00:00Z' &&
        normalizeSitecoreDateStringFormattedWithTime(
          video.fields?.primaryVideo?.fields?.lastUpdated.value
        );

      ldJsonScriptYouTube = {
        '@context': 'https://schema.org',
        '@type': 'VideoObject',
        contentURL: video.fields?.primaryVideo?.fields?.videoId?.value
          ? `https://www.youtube.com/watch?v=${video.fields?.primaryVideo?.fields?.videoId.value}`
          : '',
        description: video.fields?.primaryVideo?.fields?.videoDescription?.value || '',
        embedUrl: video.fields?.primaryVideo?.fields?.videoId?.value
          ? `https://www.youtube.com/embed/${video.fields?.primaryVideo?.fields?.videoId.value}`
          : '',
        name: video.fields?.primaryVideo?.fields?.videoName?.value || '',
        thumbnailUrl:
          (video as unknown as Foundation.EnterpriseWeb.Enterprise.BaseTemplates.BaseGalleryVideo)
            ?.fields?.videoThumbnail?.value?.src ||
          `https://i.ytimg.com/vi/${video.fields?.primaryVideo?.fields?.videoId?.value}/sddefault.jpg` ||
          '',
        uploadDate: formattedDate || '',
      };
    }

    if (
      guidEquals(
        video.fields?.primaryVideo?.templateId,
        SitecoreIds.Feature.Data.Elements.Videos.VimeoVideo.TemplateId
      )
    ) {
      const formattedDate =
        video.fields?.primaryVideo?.fields?.lastUpdated?.value &&
        video.fields?.primaryVideo?.fields?.lastUpdated?.value !== '0001-01-01T00:00:00Z' &&
        normalizeSitecoreDateStringFormattedWithTime(
          video.fields?.primaryVideo?.fields?.lastUpdated.value
        );

      let thumbnailUrl = '';
      if (Array.isArray(props.staticProps)) {
        const matchedItem = props.staticProps.find(
          (item: StaticPropsItem) =>
            item.videoId === video.fields?.primaryVideo?.fields?.videoId?.value
        );
        thumbnailUrl =
          matchedItem?.mediaPrimary.videoStaticProps.vimeoStaticProps?.videoThumbnailUrl || '';
      }

      ldJsonScriptVimeo = {
        '@context': 'https://schema.org',
        '@type': 'VideoObject',
        contentURL: `https://vimeo.com/${video.fields?.primaryVideo?.fields?.videoId?.value}`,
        description: video.fields?.primaryVideo?.fields?.videoDescription?.value || '',
        embedUrl: `https://player.vimeo.com/video/${video.fields?.primaryVideo?.fields?.videoId?.value}`,
        name: video.fields?.primaryVideo?.fields?.videoName?.value || '',
        thumbnailUrl:
          (video as unknown as Foundation.EnterpriseWeb.Enterprise.BaseTemplates.BaseGalleryVideo)
            ?.fields?.videoThumbnail?.value?.src ||
          thumbnailUrl ||
          '',
        uploadDate: formattedDate || '',
      };
    }
    return (
      <Head key={index}>
        {ldJsonScriptYouTube && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJsonScriptYouTube) }}
          />
        )}
        {ldJsonScriptVimeo && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJsonScriptVimeo) }}
          />
        )}
      </Head>
    );
  });

  return (
    <>
      {videoGalleryContent}
      <Component variant="lg" dataComponent="general/videogallery" {...props}>
        <VideoListing {...props} />
        {videoGalleryContent}
      </Component>
    </>
  );
};

export const getStaticProps: GetStaticComponentProps = async (rendering) => {
  const datasource = rendering as VideoGalleryProps;

  const mediaStaticProps: MediaPrimaryStaticPropsArray = {
    mediaPrimary: [],
  };

  if (!datasource || !datasource.fields.videos || datasource.fields.videos.length === 0) {
    return mediaStaticProps;
  }

  for (const video of datasource.fields.videos) {
    const videoId = video.fields?.primaryVideo?.fields?.videoId?.value;
    const mediaPrimary = await getMediaPrimaryStaticProps(video);
    mediaStaticProps.mediaPrimary.push({ videoId, mediaPrimary });
  }

  return mediaStaticProps;
};

export default withDatasourceCheck()<VideoGalleryProps>(VideoGallery);
