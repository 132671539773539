import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { useTheme } from 'lib/context/ThemeContext';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import Component from 'src/helpers/Component/Component';
import { ToggleBannerTheme } from './ToggleBanner.theme';
import classNames from 'classnames';
import Headline from 'src/helpers/Headline/Headline';
import { getEnum } from 'lib/utils';
import HelperButton from './ToggleBannerHelper';
import { HelperButtonClasses } from './ToggleBannerHelper';
import BodyCopy from 'src/helpers/BodyCopy/BodyCopy';
import { useState } from 'react';

export type ToggleBannerProps =
  Feature.EnterpriseWeb.AndersenWindows.Components.General.ToggleBanner.ToggleBanner;

const ToggleBanner = (props: ToggleBannerProps) => {
  const headlineLevel = getEnum<string>(props?.fields.headlineLevel);
  const LeftButton = {
    ...props?.fields?.LeftToggleLink,
    value: { ...props?.fields?.LeftToggleLink?.value, text: props?.fields?.LeftToggleText?.value },
  };
  const RightButton = {
    ...props?.fields?.RightToggleLink,
    value: {
      ...props?.fields?.RightToggleLink?.value,
      text: props?.fields?.RightToggleText?.value,
    },
  };
  const { themeData, themeName } = useTheme(ToggleBannerTheme);
  const containerWidth =
    getEnum<'fullBleed' | 'fullWidth'>(props.fields?.containerWidth) ?? 'fullWidth';
  const defaultHover = getEnum<string | string>(props.fields.ToggleChkSelection);

  const [defaulthoveredButton] = useState<1 | 2>(defaultHover === 'rightToggle' ? 2 : 1);

  const [hoveredButton, setHoveredButton] = useState<1 | 2>(defaultHover === 'rightToggle' ? 2 : 1); //Will Default to first button hovered when nothing is provided

  const iconReverse = defaulthoveredButton === 1 ? 'flex-row-reverse' : '';

  const getButtonClasses = (buttonNumber: 1 | 2) => {
    const baseClasses = HelperButtonClasses(themeName).btnClass;
    const hoverClasses =
      themeName === 'aw'
        ? 'border-theme-btn-border-hover bg-theme-btn-bg-hover text-theme-btn-text-hover'
        : 'bg-theme-btn-bg-hover text-theme-btn-text-hover';

    return classNames(
      baseClasses,
      hoveredButton === buttonNumber ? hoverClasses : '',
      buttonNumber === 1
        ? `flex relative gap-2 pl-0 text-center ${iconReverse}`
        : 'text-center mt-4 ml-0 h-[44px] md:mt-0 md:-ml-[54px]'
    );
  };

  return (
    <Component
      dataComponent="general/togglebanner"
      variant="lg"
      sectionWrapperClasses={containerWidth === 'fullBleed' ? 'bg-primary' : ''}
      gap="gap-x-0"
      padding="px-0"
      backgroundVariant="primary"
      grid="section-grid grid grid-cols-2 md:grid-cols-12 bg-primary"
      {...props}
    >
      <div className={themeData.classes.mainWrapper}>
        <div className={themeData.classes.headlineWrapper}>
          <Headline defaultTag={headlineLevel} {...props} />
          <BodyCopy {...props} />
        </div>
        <div className={themeData.classes.buttonWrappers}>
          <div
            onMouseEnter={() => setHoveredButton(1)}
            onMouseLeave={() => setHoveredButton(defaulthoveredButton)}
          >
            <HelperButton
              icon={
                defaulthoveredButton === 1
                  ? { fields: { Value: { value: 'checkround' } } }
                  : props?.fields?.LeftToggleIcon
              }
              classes={getButtonClasses(1)}
              field={LeftButton}
            />
          </div>
          <div
            onMouseEnter={() => setHoveredButton(2)}
            onMouseLeave={() => setHoveredButton(defaulthoveredButton)}
          >
            <HelperButton
              icon={
                defaulthoveredButton === 2
                  ? { fields: { Value: { value: 'checkround' } } }
                  : props?.fields?.RightToggleIcon
              }
              field={RightButton}
              classes={getButtonClasses(2)}
            />
          </div>
        </div>
      </div>
    </Component>
  );
};

export default withDatasourceCheck()<ToggleBannerProps>(ToggleBanner);
