// Global
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { useTheme } from 'lib/context/ThemeContext';
import { Field, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
// Components
import classNames from 'classnames';
import Component from 'src/helpers/Component/Component';
import { Subheadline } from 'src/helpers/Subheadline';
import Headline from 'src/helpers/Headline/Headline';
import ImagePrimary from 'src/helpers/Media/ImagePrimary';
import { RichTextWrapper } from 'src/helpers/RichTextWrapper';
import { HeroFeaturedProductTheme } from './HeroFeaturedProduct.theme';
import { getHeadingLevel } from 'lib/utils';

export type HeroFeaturedProductProps =
  Feature.EnterpriseWeb.Enterprise.Components.Hero.HeroFeaturedProduct;
const HeroFeaturedProduct = (props: HeroFeaturedProductProps) => {
  const { fields } = props;

  const { themeName, themeData } = useTheme(HeroFeaturedProductTheme);
  const subheadingList: Array<Field<string>> = [];

  if (!fields) {
    return null;
  }

  Object.keys(fields).map((key, index) => {
    if (key === `subheading${index + 1}`) {
      fields[key].value && subheadingList.push(fields[key]);
    }
  });

  return (
    <>
      <Component
        variant="lg"
        sectionWrapperClasses={themeName === 'rba' ? 'bg-light-gray' : undefined}
        dataComponent="hero/herofeaturedproduct"
        {...props}
      >
        <div className={classNames('col-span-12', themeData.classes.productWrapper)}>
          <div className={themeData.classes.headingsWrapper}>
            <Subheadline classes={themeData.classes.smallHeadline} useTag="h2" {...props} />
            <Headline
              classes={themeData.classes.largeHeadline}
              useTag={getHeadingLevel('h1', fields?.headlineLevel)}
              {...props}
            />
          </div>
          <div className={themeData.classes.imageWrapper}>
            <ImagePrimary
              maxH={'max-w-[592px]'}
              additionalDesktopClasses={themeData.classes.additionalDesktopClasses}
              additionalMobileClasses={themeData.classes.additionalDesktopClasses}
              {...props}
              priority
            />
          </div>
        </div>
      </Component>
      <Component variant="lg" {...props}>
        <div className={`col-span-12 ${themeData.classes.subheadingsList}`}>
          {subheadingList.map((item, index: number) => {
            return (
              <div key={index} className={classNames(themeData.classes.subheadingItem)}>
                <RichTextWrapper field={item} classes={themeData.classes.rteClasses} />
              </div>
            );
          })}
        </div>
      </Component>
    </>
  );
};

export default withDatasourceCheck()<HeroFeaturedProductProps>(HeroFeaturedProduct);
