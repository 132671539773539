import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { BaseSubmitAction, BaseSubmitProps, ExecutionResult } from '../../BaseSubmitAction';

type SubmitMoveToPage =
  Feature.EnterpriseWeb.RenewalByAndersen.Forms.SubmitActions.MoveToPage['fields'];

export class MoveToPage extends BaseSubmitAction {
  constructor(params: BaseSubmitProps) {
    super(params);
  }

  override async executeAction(): Promise<ExecutionResult> {
    const submitActionFields = this.actionFieldsProps as SubmitMoveToPage;
    const nextPageId: string | undefined = submitActionFields?.nextPage?.id;

    const nextPageIndex = this.formProps?.fields?.children.findIndex((page) => {
      return page.id === nextPageId;
    });

    return new Promise<ExecutionResult>((resolve) => {
      resolve({
        result: {},
        success: true,
        errorMessage: this.actionFieldsProps?.errorMessage?.value,
        nextPageIndex: nextPageIndex,
      });
    });
  }
}
