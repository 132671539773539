import { useTheme } from 'lib/context/ThemeContext';
import LinkWrapper from 'src/helpers/LinkWrapper/LinkWrapper';
import { IconTypes } from 'src/helpers/SvgIcon';
import SvgIcon from 'src/helpers/SvgIcon/SvgIcon';
import { getEnum } from 'lib/utils';
import classNames from 'classnames';
import { ButtonProps } from 'src/helpers/Button/Button';

type HelperButtonProps = Omit<ButtonProps, 'variant'>;

export const HelperButtonClasses = (themeName: string) => {
  if (themeName === 'aw') {
    return {
      btnClass:
        'flex w-fit min-w-[280px] items-center justify-center whitespace-nowrap rounded-lg border-4 border-theme-btn-border bg-theme-btn-bg py-[8px] pr-xxs font-sans text-button font-heavy',
      iconClass: 'ml-xxs',
    };
  } else {
    return {
      btnClass: '',
      iconClass: '',
    };
  }
};

const HelperButton = (props: HelperButtonProps): JSX.Element => {
  const { themeName } = useTheme();
  const { field, icon, classes, ariaLabel } = props;
  const _icon = getEnum<IconTypes>(icon);

  if (field) {
    return (
      <LinkWrapper
        onClick={(event) => {
          if (!field?.value?.href) {
            event.preventDefault(); // Prevent default if ctaLink does not exist
          }
        }}
        field={field}
        ariaLabel={ariaLabel}
        className={classNames(HelperButtonClasses(themeName).btnClass, classes)}
      >
        {icon && <SvgIcon icon={_icon} className={HelperButtonClasses(themeName).iconClass} />}
      </LinkWrapper>
    );
  }

  return <></>;
};

export default HelperButton;
