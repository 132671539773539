// Lib
import { ThemeFile } from 'lib/context/ThemeContext';

export const ToggleBannerTheme: ThemeFile = {
  aw: {
    classes: {
      /** Insert Theme classes here **/
      mainWrapper: 'col-span-12 flex flex-col flex-wrap ml:flex-row',
      headlineWrapper: 'flex-1 px-[15px] pt-m md:py-m lg:px-m lg:py-[24px]',
      buttonWrappers: 'flex flex-col p-4 md:flex-row ml:justify-end ml:self-center',
    },
  },
  rba: {
    classes: {
      /** Insert Theme classes here **/
    },
  },
};
