import useSWR from 'swr';
import { useAffiliate } from 'lib/context/AffiliateContext';
import { fetchInternalAPIWithQueryString } from './api-request-utils';

export interface offerProps {
  affiliateSubheading: string | undefined;
  substring(arg0: number, arg1: number): unknown;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  affiliateID: any;
  data: any;
  link: any;
  headlineText: any;
  legalDisclaimer: any;
  offerDetails: any;
  offerEndDate: any;
  subHeadlineText: any;
  /* eslint-enable @typescript-eslint/no-explicit-any */
}

export const useOfferCards = () => {
  const { pageAffiliate, userAffiliate } = useAffiliate();

  const affiliateID = pageAffiliate?.affiliateId || userAffiliate?.affiliateId;

  const { data: offerData, isLoading } = useSWR(
    `/api/rba/offers/get-current-offer-for-affiliate/?storeid=${affiliateID}`,
    (url) => fetcher(url),
    { revalidateOnFocus: false, revalidateIfStale: false }
  );

  const fetcher = async (url: string) => {
    const response = await fetchInternalAPIWithQueryString(url);
    const responseData = await response.json();
    return responseData?.results;
  };

  return { isLoading, offerData };
};
