import { Foundation } from 'src/.generated/Foundation.EnterpriseWeb.model';
import { getEnum, getEnumsFromMultiselectField } from 'lib/utils/get-enum';

type IsValidForEnvironmentProps =
  Foundation.EnterpriseWeb.Enterprise.FieldSets.Advanced.ValidForEnvironment;

export type EnvironmentStates = 'Disabled' | 'All' | 'Selected';
export type Environments = 'Local' | 'Development' | 'UAT' | 'Production';

export const isValidForEnvironment = (fieldset: IsValidForEnvironmentProps): boolean => {
  const currentEnvironment = process.env.NEXT_PUBLIC_EW_ENVIRONMENT || process.env.NODE_ENV;

  const environmentState =
    getEnum<EnvironmentStates>(fieldset?.fields?.environmentState) ?? 'Disabled';
  const validForEnvironments =
    getEnumsFromMultiselectField<Environments>(fieldset?.fields?.validEnvironments) ?? [];

  switch (environmentState) {
    case 'Disabled':
      return false;

    case 'All':
      return true;

    case 'Selected':
      const matchedEnvironment = validForEnvironments.some((x) => x === currentEnvironment);
      return matchedEnvironment;
    default:
      return false;
  }
};
