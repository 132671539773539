/* eslint-disable @typescript-eslint/no-unused-vars */
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import React from 'react';
import * as yup from 'yup';
import { FormFieldProps, GetStaticFormFieldProps } from 'lib/forms/FormFieldProps';
import FieldWrapper from '../../Structure/FieldWrapper/FieldWrapper';
import { JobLocationSelector } from './JobLocation.helpers';
import { getEnum } from 'lib/utils';
import { Foundation } from 'src/.generated/Foundation.EnterpriseWeb.model';
import { useFormFieldProps } from 'lib/forms/FormContext';
import { MappedJobLocationData } from 'lib/forms/JobLocationDropdown/jobLocation.Types';
import { graphQLJobLocationDataService } from 'lib/forms/JobLocationDropdown/graphql-job-location-data-service';

export type JobLocationDropdownProps =
  Feature.EnterpriseWeb.RenewalByAndersen.Forms.Fields.JobLocationDropdown & FormFieldProps;

export const getInitialValue = (props: JobLocationDropdownProps) => {
  if (props?.fields?.defaultValue) {
    return props?.fields?.defaultValue?.value || [];
  }
  return [];
};

export const getValidationSchema = (props: JobLocationDropdownProps, schema: yup.AnyObject) => {
  const { validations } = props?.fields;
  if (validations) {
    const requiredValidationRule = validations.find(
      (validationItem: Foundation.EnterpriseWeb.Enterprise.FieldSets.Forms.FieldValidation) =>
        getEnum(validationItem.fields?.validationType) === 'required'
    );

    const validator =
      requiredValidationRule &&
      yup.array().min(1, requiredValidationRule.fields?.errorMessage?.value);
    if (validator) {
      schema[props?.fields?.fieldName?.value] = validator as yup.AnySchema;
    }
  } else {
    schema[props?.fields?.fieldName?.value] = yup.array() as yup.AnySchema;
  }
  return schema;
};

const JobLocationDropdown = (props: JobLocationDropdownProps): JSX.Element => {
  const fetchedData = useFormFieldProps(props.id) as MappedJobLocationData[];

  return (
    <FieldWrapper {...props}>
      <JobLocationSelector
        name={props?.fields?.fieldName?.value || ''}
        options={fetchedData}
        placeholderText={props.fields?.placeholder}
      />
    </FieldWrapper>
  );
};

export const getStaticProps: GetStaticFormFieldProps = async (_field, _rendering, layoutData) => {
  const data = graphQLJobLocationDataService.getJobLocationData(
    layoutData?.sitecore?.context?.language || 'en'
  );

  return data;
};

export default JobLocationDropdown;
