import { create } from 'zustand';

type Store = {
  isChatButtonVisible: boolean;
  chatButtonUrl: string;
  windowWidth: string;
  windowHeight: string;
  setChatButtonVisible: (shouldChatButtonVisible: boolean) => void;
  setChatButtonUrl: (chatUrl: string) => void;
  setWindowWidth: (windowWidth: string) => void;
  setWindowHeight: (windowHeight: string) => void;
};

export const useChatButtonStore = create<Store>()((set) => ({
  isChatButtonVisible: false,
  chatButtonUrl: '',
  windowWidth: '',
  windowHeight: '',
  setChatButtonVisible: (shouldChatButtonVisible: boolean) =>
    set(() => ({ isChatButtonVisible: shouldChatButtonVisible })),
  setChatButtonUrl: (chatUrl: string) => set(() => ({ chatButtonUrl: chatUrl })),
  setWindowWidth: (windowWidth: string) => set(() => ({ windowWidth: windowWidth })),
  setWindowHeight: (windowHeight: string) => set(() => ({ windowHeight: windowHeight })),
}));
