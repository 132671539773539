import { OnlineSchedulingConstants } from 'lib/constants/online-scheduling';
import TagManager from 'react-gtm-module';
import { CRMResponse, OnlineSchedulingMessage } from './OnlineSchedulingTypes';

export const sendMessageToParentPage = (
  message: OnlineSchedulingMessage,
  targetOrigin: string | undefined
) => {
  if ('parentIFrame' in window && !!message && !!targetOrigin) {
    targetOrigin = `https://${targetOrigin}`;
    // @ts-ignore https://github.com/davidjbradshaw/iframe-resizer/blob/master/docs/iframed_page/methods.md#iframe-page-methods
    window.parentIFrame.sendMessage(message, targetOrigin);
  }

  if (message?.type === OnlineSchedulingConstants.events.onError) {
    TagManager.dataLayer({
      dataLayer: {
        event: message.type,
        description: message.description,
      },
    });
  }
};

export const sendSubmittedEvent = (
  crmResponse: CRMResponse,
  leadData: any,
  osDomain: string | undefined
) => {
  if (!crmResponse?.data?.resultId && !crmResponse?.data?.message) {
    return;
  }

  const onSubmittedMessage = {
    type: OnlineSchedulingConstants.events.onSubmitted,
    description: OnlineSchedulingConstants.events.descriptions.onSubmittedDescription,
    leadData: {
      resultId: crmResponse?.data?.resultId,
      resultMessage: crmResponse?.data?.message,
      ...leadData,
    },
  };
  sendMessageToParentPage(onSubmittedMessage, osDomain);
};
