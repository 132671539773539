import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { Project } from 'src/.generated/Project.AndersenCorporation.model';

export function getShowroom({ context }: { context: any }): ShowroomData {
  // check to see if we're on a showroom page by casting current page as ShowroomPage, then checking its fields.
  const showroomPage = context.sitecoreContext
    .route as Project.AndersenCorporation.RenewalByAndersen.Pages.ShowroomPage;
  const showroomPageUrl = (showroomPage?.fields?.showroom?.fields?.showroomPage as LinkField)?.value
    ?.href;
  const showroom = showroomPage.fields?.showroom as
    | Feature.EnterpriseWeb.RenewalByAndersen.Data.Affiliates.Datasources.Showroom
    | undefined;

  return {
    showroom: showroom,
    showroomPageUrl: showroomPageUrl,
    showroomPage: showroomPage,
  };
}

export type ShowroomData = {
  showroom:
    | Feature.EnterpriseWeb.RenewalByAndersen.Data.Affiliates.Datasources.Showroom
    | undefined;
  showroomPageUrl: string | undefined;
  showroomPage: Project.AndersenCorporation.RenewalByAndersen.Pages.ShowroomPage;
};
