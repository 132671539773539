// Global
import { VideoModalTheme } from './VideoModal.theme';
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { useEffect, useState } from 'react';
import { useModalIdContext } from 'lib/context/GenericModalIDContext';
import ModalWrapper from 'src/helpers/ModalWrapper/ModalWrapper';
import VideoWrapper from 'src/helpers/Media/VideoWrapper';
import { useTheme } from 'lib/context/ThemeContext';

export type VideoModalProps =
  Feature.EnterpriseWeb.Enterprise.Components.Modal.VideoModal.VideoModal;
const VideoModal = (props: VideoModalProps): JSX.Element => {
  const { themeData } = useTheme(VideoModalTheme);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { selectedModalId, setSelectedModalId, prevFocusedElementRef } = useModalIdContext();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedModalId('');
    prevFocusedElementRef?.current?.focus();
  };
  useEffect(() => {
    if (selectedModalId) {
      selectedModalId === props.fields?.modalId.value && handleOpenModal();
    }
    if (selectedModalId === '') {
      handleCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModalId]);

  return (
    <div data-component="modal/videomodal">
      <ModalWrapper
        size={'large'}
        handleClose={handleCloseModal}
        modalLabel={props?.fields?.modalId.value}
        isModalOpen={isModalOpen}
      >
        <div className={themeData.classes.videoContainer}>
          <div className={themeData.classes.headline}>
            <h2 className="text-lg font-semibold">{props?.fields?.videoHeadline.value}</h2>
          </div>
          <VideoWrapper videoItem={props?.fields?.video} />
        </div>
      </ModalWrapper>
    </div>
  );
};
export default VideoModal;
