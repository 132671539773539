import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { BaseSubmitAction, BaseSubmitProps, ExecutionResult } from '../../BaseSubmitAction';
import { FormsConstants } from 'lib/constants/forms-constants';

type SubmitJobLeadGenFields =
  Feature.EnterpriseWeb.RenewalByAndersen.Forms.SubmitActions.SubmitJobLeadGen['fields'];

export class SubmitJobLeadGen extends BaseSubmitAction {
  constructor(params: BaseSubmitProps) {
    super(params);
  }

  override async executeAction(): Promise<ExecutionResult> {
    // If error occurs while constructing payload, reject the promise

    const formDataCopy = { ...this.formData };
    const submitActionFields = this.actionFieldsProps as SubmitJobLeadGenFields;
    const formType =
      submitActionFields?.formType as unknown as Feature.EnterpriseWeb.RenewalByAndersen.Forms.FormType;
    formDataCopy[FormsConstants.RBA.FieldNames.FormType] =
      formType?.fields?.value?.value || FormsConstants.RBA.FormTypes.JobInquiry;
    formDataCopy[FormsConstants.RBA.FieldNames.Sender] = submitActionFields?.sender?.value;
    if (!formDataCopy) {
      return new Promise<ExecutionResult>((_, reject) => {
        reject({
          success: false,
          errorMessage: this.actionFieldsProps?.errorMessage?.value,
        });
      });
    }

    const requestLeadGen = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formDataCopy),
    };

    const response = await fetch(`/api/rba/submit-actions/submit-job-lead-gen/`, requestLeadGen);

    if (response.status === 200) {
      super.setActionCookie(true);
    }

    return new Promise<ExecutionResult>((resolve) => {
      resolve({
        success: response.status === 200,
        errorMessage: this.actionFieldsProps?.errorMessage?.value,
      });
    });
  }
}
