import { Foundation } from 'src/.generated/Foundation.EnterpriseWeb.model';
import ImageWrapper from './ImageWrapper';
import VideoWrapper, { getStaticProps as getVideoStaticProps } from './VideoWrapper';
import { LayoutValue, MediaStaticProps, RatioTypes, maxhTypes, maxwTypes } from '.';

export type ImagePrimaryProps = Foundation.EnterpriseWeb.Enterprise.FieldSets.ImageSecondary &
  Foundation.EnterpriseWeb.Enterprise.FieldSets.ImageSecondaryCaption &
  Foundation.EnterpriseWeb.Enterprise.FieldSets.VideoSecondary & {
    imageLayout?: LayoutValue;
    additionalDesktopClasses?: string;
    additionalMobileClasses?: string;
    ratio?: RatioTypes;
    maxH?: maxhTypes;
    maxW?: maxwTypes;
    focusArea?: string;
    rendering?: {
      componentName?: string;
    };
    staticProps?: MediaStaticProps;
  };

// include SEO Schema For Vimeo and YouTube Videos for - ContentBlockWithMedia HeroHalfMedia PromoFeaturedMedia PromoGeneric PromoReviewContentAuthored
// PromoSwatches. GenericCard, Video Gallery and Video Gallery Dynamic : script added at component level

const MediaSecondary = (props: ImagePrimaryProps): JSX.Element => {
  const componentName = props?.rendering?.componentName as string;
  const includeSEOSchemaForVimeoYouTube = [
    'ContentBlockWithMedia',
    'HeroHalfMedia',
    'PromoFeaturedMedia',
    'PromoGeneric',
    'PromoReviewContentAuthored',
    'PromoSwatches',
  ].includes(componentName);
  if (props.fields?.secondaryVideo) {
    return (
      <VideoWrapper
        videoItem={props.fields?.secondaryVideo}
        includeSEOSchemaForVimeoYouTube={includeSEOSchemaForVimeoYouTube}
      />
    );
  }

  const params = {
    image: props.fields?.secondaryImage,
    mobileImage: props.fields?.secondaryImageMobile,
    mobileFocusArea: props.fields?.secondaryImageMobileFocusArea,
    caption: props.fields?.secondaryImageCaption,
    imageLayout: props.imageLayout,
    additionalDesktopClasses: props.additionalDesktopClasses,
    additionalMobileClasses: props.additionalMobileClasses,
    ratio: props.ratio,
    maxH: props.maxH,
    maxW: props.maxW,
    focusArea: props.focusArea,
  };

  return <ImageWrapper {...params} />;
};

export const getStaticProps = async (props: ImagePrimaryProps): Promise<MediaStaticProps> => {
  const result: MediaStaticProps = {};

  if (props.fields?.secondaryVideo) {
    result.videoStaticProps = await getVideoStaticProps(props.fields?.secondaryVideo);
  }

  return result;
};

export default MediaSecondary;
