// Global
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
//import { useTheme } from 'lib/context/ThemeContext';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';

// Components
import Component from 'src/helpers/Component/Component';
//import { ProductVisualizerTheme } from './ProductVisualizer.theme';
import { ComponentProps } from 'lib/types/component-props';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export type ProductVisualizerProps =
  Feature.EnterpriseWeb.RenewalByAndersen.Components.Product.ProductVisualizer.ProductVisualizer &
    ComponentProps;

const ProductVisualizer = (props: ProductVisualizerProps) => {
  //const { themeData } = useTheme(ProductVisualizerTheme);
  const router = useRouter();

  useEffect(() => {
    if (router.isReady) {
      async function getMakeabilityAndOptionAutosetRules() {
        try {
          const makeabilityRulesPath = '/api/rba/product-visualizer/get-makeability-rules-by-id/';
          const makeabilityRulesResponse = await fetch(makeabilityRulesPath, {
            method: 'POST',
            body: JSON.stringify({
              itemId: props?.fields?.productLineVisualizerConfiguration?.id,
              language: 'en',
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          });

          const makeabilityRulesResults = await makeabilityRulesResponse.json();
          console.log('makeabilityRulesResults', makeabilityRulesResults);

          const optionAutosetRulesPath =
            '/api/rba/product-visualizer/get-option-autoset-rules-by-id/';
          const optionAutosetRulesResponse = await fetch(optionAutosetRulesPath, {
            method: 'POST',
            body: JSON.stringify({
              itemId: props?.fields?.productLineVisualizerConfiguration?.id,
              language: 'en',
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          });

          const optionAutosetRulesResults = await optionAutosetRulesResponse.json();
          console.log('optionAutosetRulesResults', optionAutosetRulesResults);
        } catch {}
      }

      getMakeabilityAndOptionAutosetRules();
    }
  }, [router.asPath, router.isReady]);

  return (
    <Component variant="lg" dataComponent="product/productvisualizer" {...props}>
      ProductVisualizer
    </Component>
  );
};

export default withDatasourceCheck()<ProductVisualizerProps>(ProductVisualizer);
