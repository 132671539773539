import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { BaseSubmitAction, BaseSubmitProps, ExecutionResult } from '../../BaseSubmitAction';
import { FormsConstants } from 'lib/constants/forms-constants';

type SubmitJobApplicantFields =
  Feature.EnterpriseWeb.RenewalByAndersen.Forms.SubmitActions.SubmitJobApplicant['fields'];

export class SubmitJobApplicant extends BaseSubmitAction {
  constructor(params: BaseSubmitProps) {
    super(params);
  }

  override async executeAction(): Promise<ExecutionResult> {
    const formDataCopy = { ...this.formData };
    const submitActionFields = this.actionFieldsProps as SubmitJobApplicantFields;
    const formType =
      submitActionFields?.formType as unknown as Feature.EnterpriseWeb.RenewalByAndersen.Forms.FormType;
    formDataCopy[FormsConstants.RBA.FieldNames.FormType] =
      formType?.fields?.value?.value || FormsConstants.RBA.FormTypes.JobInquiry;
    formDataCopy[FormsConstants.RBA.FieldNames.Sender] = submitActionFields?.sender?.value;
    // If error occurs while constructing payload, reject the promise
    if (!formDataCopy) {
      return new Promise<ExecutionResult>((_, reject) => {
        reject({
          success: false,
          errorMessage: this.actionFieldsProps?.errorMessage?.value,
        });
      });
    }

    const requestApplication = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formDataCopy),
    };

    const response = await fetch(
      `/api/rba/submit-actions/submit-job-applicant/`,
      requestApplication
    );

    return new Promise<ExecutionResult>((resolve) => {
      resolve({
        success: response.status === 200,
        errorMessage: this.actionFieldsProps?.errorMessage?.value,
      });
    });
  }
}
