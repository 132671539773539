import { useExternalScript } from 'lib/utils/use-external-script';

export const useBVScript = (props: UseBVScriptProps) => {
  const { environment, theme } = props;

  const bazaarvoiceScriptUrl =
    theme === 'aw'
      ? environment && environment === 'Production'
        ? 'https://apps.bazaarvoice.com/deployments/andersenwindows/main_website_dxp/production/en_US/bv.js'
        : 'https://apps.bazaarvoice.com/deployments/andersenwindows/main_website_dxp/staging/en_US/bv.js'
      : '';

  const bazaarvoiceScriptState = useExternalScript(bazaarvoiceScriptUrl);

  return bazaarvoiceScriptState;
};

export interface UseBVScriptProps {
  environment?: string;
  theme: string;
}
