export const customImageLoader = ({
  src,
  width,
  quality,
}: {
  src: string;
  width: number;
  quality?: number;
}) => {
  try {
    const baseUrl = process.env.PUBLIC_URL?.replace(/\/+$/, '') || ''; // Trim trailing slashes
    if (!src || typeof src !== 'string') {
      throw new Error('Invalid src provided');
    }
    src = src.replace(/^https:\/\/edge\.sitecorecloud\.io\/andersencorporation-[^/]+\//, '/-/');

    const defaultUrl = `${baseUrl}/_next/image?url=${encodeURIComponent(src)}&w=${width}&q=${
      quality || 75
    }`;
    return defaultUrl;
  } catch (error) {
    console.error('Error in customImageLoader:', error);
    throw error;
  }
};
