// Global
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
// Components

import Form from '../Form/Form';
import { useExternalScript } from 'lib/utils/use-external-script';
import { getEnum } from 'lib/utils';

export type ConsultationRequestProps =
  Feature.EnterpriseWeb.RenewalByAndersen.Forms.ConsultationRequestForm;
const ConsultationRequest = (props: ConsultationRequestProps) => {
  const scriptVersion = getEnum<string>(props.fields.scriptVersion) || 'v1';
  const key = process.env.NEXT_PUBLIC_EW_ONLINE_SCHEDULING_KEY || '';
  const domain = process.env.NEXT_PUBLIC_EW_ONLINE_SCHEDULING_DOMAIN || '';
  const script = `${domain}/os/${scriptVersion}/osiframe.js?key=${key}`;
  useExternalScript(script);

  return <Form {...props} />;
};

export default withDatasourceCheck()<ConsultationRequestProps>(ConsultationRequest);
