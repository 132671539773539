import { useExternalScript } from 'lib/utils/use-external-script';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    a2a: any;
  }
}

export const useA2AScript = () => {
  const a2aScript = 'https://static.addtoany.com/menu/page.js';
  const a2aScriptState = useExternalScript(a2aScript);

  return a2aScriptState;
};

export const shareServicesToExclude = [
  'amazon_wish_list',
  'aol_mail',
  'balatarin',
  'bibsonomy',
  'bitty_browser',
  'blinklist',
  'blogger',
  'blogmarks',
  'bookmarks_fr',
  'box_net',
  'buffer',
  'care2_news',
  'citeulike',
  'copy_link',
  'design_float',
  'diary_ru',
  'diaspora',
  'digg',
  'diigo',
  'douban',
  'draugiem',
  'dzone',
  'evernote',
  'facebook_messenger',
  'fark',
  'flipboard',
  'folkd',
  'google_bookmarks',
  'google_classroom',
  'hacker_news',
  'hatena',
  'houzz',
  'instapaper',
  'kakao',
  'kik',
  'kindle_it',
  'known',
  'line',
  'linkedin',
  'livejournal',
  'mail_ru',
  'mastodon',
  'mendeley',
  'meneame',
  'mewe',
  'mix',
  'mixi',
  'myspace',
  'netvouz',
  'odnoklassniki',
  'papaly',
  'pinboard',
  'plurk',
  'pocket',
  'print',
  'printfriendly',
  'protopage_bookmarks',
  'pusha',
  'qzone',
  'reddit',
  'rediff',
  'refind',
  'renren',
  'sina_weibo',
  'sitejot',
  'skype',
  'slashdot',
  'sms',
  'stocktwits',
  'svejo',
  'symbaloo_bookmarks',
  'telegram',
  'threema',
  'trello',
  'tuenti',
  'tumblr',
  'twiddla',
  'twitter',
  'typepad_post',
  'viadeo',
  'viber',
  'vk',
  'wanelo',
  'wechat',
  'whatsapp',
  'wordpress',
  'wykop',
  'xing',
  'yoolink',
  'yummly',
];
