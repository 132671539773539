import Script, { ScriptProps } from 'next/script';

import { getEnum } from 'lib/utils/get-enum';
import { hashCode } from 'lib/utils/hash-code';
import { isValidForEnvironment } from 'lib/utils/is-valid-for-environment';
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { ComponentProps } from 'lib/types/component-props';
import { useMemo } from 'react';
import { createUUID } from 'lib/utils/string-utils';

export type HeadScriptProps = Feature.EnterpriseWeb.Enterprise.Components.Advanced.HeadScript &
  ComponentProps;

const HeadScript = (props: HeadScriptProps): JSX.Element | null => {
  const { fields, rendering } = props;

  const id = useMemo(
    (): string => fields?.id?.value ?? `id${hashCode(rendering.uid ?? createUUID())}`,
    [fields?.id?.value, rendering.uid]
  );
  const type = fields?.type?.value || 'text/javascript';
  const src = fields?.src?.value ?? '';
  const body = fields?.htmlBody?.value ?? '';
  const strategy = getEnum<ScriptProps['strategy']>(fields?.strategy) ?? 'beforeInteractive';

  //Leaving this to be implemented in the future to inject the comments.
  //const name = fields?.name?.value;
  //const beginCommment = document.createComment(` Begin ${name} Script `);
  //const closeComment = document.createComment(` Begin ${name} Script `);

  if (!isValidForEnvironment(props) || (!src && !body)) {
    return null;
  }
  return src ? (
    <Script id={id} type={type} src={src} strategy={strategy} />
  ) : (
    <Script id={id} type={type} strategy={strategy} dangerouslySetInnerHTML={{ __html: body }} />
  );
};

export default HeadScript;
