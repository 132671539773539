import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import React from 'react';
import { FormFieldProps } from 'lib/forms/FormFieldProps';
import { FormFieldsTheme } from './FormFields.Theme';
import { useTheme } from 'lib/context/ThemeContext';
import { BodyCopy } from 'src/helpers/BodyCopy';
import classNames from 'classnames';
import { getEnum } from 'lib/utils';
import { useOnlineScheduling } from 'lib/forms/OnlineScheduling/OnlineSchedulingContext';
import { replacePlaceholders } from 'lib/forms/FormFieldUtils';

export type DescriptionProps = Feature.EnterpriseWeb.Enterprise.Forms.Elements.Description &
  FormFieldProps;

export type alignmentStatus = 'left' | 'center' | 'right';

const Description = (props: DescriptionProps) => {
  const { tertiarySubmissionResponse, secondarySubmissionResponse } = useOnlineScheduling();
  const { themeData } = useTheme(FormFieldsTheme);
  const placeHolders: Record<string, string> = {};

  if (tertiarySubmissionResponse && tertiarySubmissionResponse.data?.appointmentDateTime) {
    const apptDate = new Date(
      tertiarySubmissionResponse.data?.appointmentDateTime
    ).toLocaleDateString('en-us', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    placeHolders['appointmentDateTime'] = apptDate;

    if (secondarySubmissionResponse) {
      const salesRepName =
        secondarySubmissionResponse.data?.salesRepName != undefined &&
        secondarySubmissionResponse.data?.salesRepName.length > 1
          ? ' with ' + secondarySubmissionResponse.data?.salesRepName
          : '';
      placeHolders['salesRepName'] = salesRepName;
    }

    props.fields.body.value = replacePlaceholders(props.fields?.body.value, placeHolders, true);
  }

  if (!props.fields) {
    return <></>;
  }

  const descriptionAlignment: Record<alignmentStatus, string> = {
    left: 'text-left',
    center: 'text-center',
    right: 'text-right',
  };

  return (
    <div className="relative col-span-12">
      <BodyCopy
        classes={classNames(
          descriptionAlignment[getEnum<alignmentStatus>(props?.fields?.alignment) || 'left'],
          themeData.classes.description
        )}
        fields={{ body: props.fields?.body }}
      />
    </div>
  );
};

export default Description;
