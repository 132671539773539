// Lib
import { ThemeFile } from 'lib/context/ThemeContext';

export const VideoModalTheme: ThemeFile = {
  aw: {
    classes: {
      /** Insert Theme classes here **/
      headline: 'relative mb-4 flex items-center justify-between',
      videoContainer:
        'w-full md:w-[50vw] h-auto max-h-[540px] md:max-h-[680px] bg-white p-8 shadow-lg ',
      iconClass: 'bg-gray-200 text-gray-800 h-4 w-4',
    },
  },
  rba: {
    classes: {
      /** Insert Theme classes here **/
      headline: 'relative mb-4 flex items-center justify-between',
      videoContainer:
        'w-full md:w-[50vw] h-auto max-h-[540px] md:max-h-[680px] bg-white p-8 shadow-lg',
      iconClass: 'bg-gray-200 text-gray-800 h-4 w-4',
    },
  },
};
