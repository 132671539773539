/* eslint-disable @next/next/inline-script-id */
import Script from 'next/script';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { Foundation } from 'src/.generated/Foundation.EnterpriseWeb.model';
import { SITE_NAMES } from 'lib/constants/sitenames';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderScripts = (sitecoreContext: any) => {
  /* Set the beacon script src based on Vercel CONTENT_ROLE environment variable. */
  const beaconSrc =
    process.env.NEXT_PUBLIC_EW_CONTENT_ROLE === 'www'
      ? '//cdn.evgnet.com/beacon/renewalbyandersen/aw_prod24/scripts/evergage.min.js'
      : '//cdn.evgnet.com/beacon/renewalbyandersen/aw_dev24/scripts/evergage.min.js';

  if (
    process.env.NEXT_PUBLIC_EW_ENVIRONMENT !== 'Local' &&
    sitecoreContext.site.name === SITE_NAMES.AndersenWindows
  ) {
    return (
      <>
        <Script type="text/javascript" strategy="beforeInteractive" src={beaconSrc}></Script>
      </>
    );
  }

  /* If all conditions are false, return nothing */
  return <></>;
};

const HeadScripts = (props: Foundation.EnterpriseWeb.Enterprise.BaseTemplates.BaseComponent) => {
  return renderScripts(props.sitecoreContext);
};
export default withSitecoreContext()(HeadScripts);
