import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { useEffect, useState } from 'react';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import Component from 'src/helpers/Component/Component';
import { useRouter } from 'next/router';
import { useTheme } from 'lib/context/ThemeContext';
import { RbAConsultRequestTheme } from './RbAConsultRequest.theme';
import { RichTextWrapper } from 'src/helpers/RichTextWrapper';
import { Spinner } from 'src/helpers/Spinner';
import useSWR from 'swr';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { setCookie } from 'cookies-next';
export type RbAConsultRequestProps =
  Feature.EnterpriseWeb.AndersenWindows.Components.General.RbAConsultRequest.RbAConsultRequest;

const RbAConsultRequest = (props: RbAConsultRequestProps) => {
  const hasSidebar =
    props.fields?.sidebarContentHeading.value !== '' || props.fields?.sidebarBodyCopy.value !== '';
  const { themeData } = useTheme(RbAConsultRequestTheme(hasSidebar));
  const [displayErrorMsg, setDisplayErrorMsg] = useState(false);
  const [percentile, setPercentile] = useState(0);

  const router = useRouter();
  const [offerData, setOfferData] = useState([]);
  const [dataFetchComplete, setDataFetchComplete] = useState(false);
  const hideElements = (item: HTMLElement) => {
    const myElement = item;
    if (myElement.id === 'rbaConsultReqForm') {
      return;
    } else {
      myElement.style.display = 'none';
    }
  };

  const fetcher = async (url: string) => {
    const urlSearchParams = new URLSearchParams(router.asPath.split('?')[1]);
    let queryStringStoreId = '';
    urlSearchParams.forEach((value, key) => {
      if (key.toLowerCase() === 'storeid') {
        queryStringStoreId = value.toString();
      }
    });

    if (queryStringStoreId) {
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(queryStringStoreId),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const responseData = await response.json();
      return responseData;
    } else {
      // we need the store Id, so display the error message and hide other content if
      // the store id is not present
      setDisplayErrorMsg(true);
      document.title = 'Error 404';

      const heroSection = document.querySelectorAll('#hero');
      heroSection.forEach(hideElements);

      const mainSection = document.querySelectorAll(
        '#main > section:not([data-component="rbaConsultRequest"])'
      );
      mainSection.forEach(hideElements);

      const breadCrumbSection = document.querySelectorAll('#scrollBreadcrumb');
      breadCrumbSection.forEach(hideElements);

      throw new Error('Missing store id from query string.');
    }
  };

  const { data: offerResults, error: offerError } = useSWR(
    '/api/aw/rba-consult-request/get-offer-by-store-id/',
    (url) => fetcher(url)
  );

  useEffect(() => {
    if (offerResults) {
      setPercentile((Number(offerResults.results.ReviewRating) / 5) * 100);
      setOfferData(offerResults.results);
      setDataFetchComplete(true);
    }

    if (offerError) {
      console.log('offerError: ', offerError);
      setDataFetchComplete(true);
    }
  }, [offerResults, offerError]);

  useEffect(() => {
    const addScore = () => {
      const myElement = document.querySelector('#starRating') as HTMLElement;
      if (!myElement) {
        return;
      } else {
        const starSpan =
          '<span class="stars-container">★★★★★<style>.stars-container:after { width: ' +
          percentile +
          '%} </style></span>';
        myElement.innerHTML = starSpan;
      }
    };

    const replaceKeyValues = (item: HTMLElement) => {
      const myElement = item;
      if (myElement.id === 'rbaConsultReqForm') {
        return;
      } else {
        let html = myElement.outerHTML;
        for (const key in offerData) {
          const re = new RegExp('{{' + key + '}}', 'gi');
          html = html.replace(re, offerData[key]);
        }
        myElement.outerHTML = html;
      }
    };

    if (dataFetchComplete) {
      // Update tokens on the page with the data received from offers API
      const ppcTrackingPhoneNumber = document.querySelectorAll('a[href^="tel:"]');
      ppcTrackingPhoneNumber.forEach(replaceKeyValues);

      const heroSections = document.querySelectorAll('#hero > section h1');
      heroSections.forEach(replaceKeyValues);

      const breadCrumbSection = document.querySelectorAll('#scrollBreadcrumb');
      breadCrumbSection.forEach(replaceKeyValues);

      const heroSection = document.querySelectorAll('#rbaConsultReqHero');
      heroSection.forEach(replaceKeyValues);

      const aboutSection = document.querySelectorAll('#rbaConsultReqAbout .rba-consult-req-about');
      aboutSection.forEach(replaceKeyValues);

      const disclaimerSection = document.querySelectorAll('#rbaConsultReqDisclaimer');
      disclaimerSection.forEach(replaceKeyValues);

      const sidebarSection = document.querySelectorAll('#rbaConsultRequestSideBar');
      sidebarSection.forEach(replaceKeyValues);

      addScore();
    }
  }, [dataFetchComplete, offerData, percentile]);

  useEffect(() => {
    const encodeOverride = (value: string) => {
      return value;
    };
    props.fields?.rbaSource?.value &&
      setCookie('rbaSource', props.fields?.rbaSource?.value, { encode: encodeOverride });
    props.fields?.rbaBreakdown?.value &&
      setCookie('rbaBreakdown', props.fields?.rbaBreakdown?.value, { encode: encodeOverride });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Component variant="lg" dataComponent="rbaConsultRequest" {...props}>
      <div className="col-span-12">
        {displayErrorMsg && (
          <RichTextWrapper
            field={{ value: props.fields?.missingStoreIdErrorMessage.value }}
            classes={themeData.classes.body}
          />
        )}
        {!displayErrorMsg && (
          <div className={themeData.classes.contentContainer}>
            <div className={themeData.classes.content}>
              {props.fields?.standardIntroContentHeading && (
                <h2 className={themeData.classes.headlineClass}>
                  {props.fields?.standardIntroContentHeading.value}
                </h2>
              )}
              <RichTextWrapper
                field={{ value: props.fields?.standardIntroContentBody.value }}
                classes={themeData.classes.body}
              />
              <div id="rbaConsultReqForm">
                <Placeholder name="form" rendering={props.rendering} />
              </div>
            </div>
            {hasSidebar && (
              <div id="rbaConsultRequestSideBar" className={themeData.classes.sidebar}>
                <div className={themeData.classes.sidebarWrapper}>
                  <h2 className={themeData.classes.sidebarH2}>
                    {props.fields?.sidebarContentHeading.value}
                  </h2>
                  <h3 className={themeData.classes.sidebarH3}>
                    {props.fields?.sidebarSubHeadline.value}
                  </h3>
                  <p className={themeData.classes.offerDetails}>
                    {props.fields?.sidebarOfferDetails.value}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
        {!dataFetchComplete && (
          <div className={themeData.classes.spinnerWrapper} id="cover">
            <div className={themeData.classes.spinner}>
              <Spinner size={48} />
            </div>
          </div>
        )}
      </div>
    </Component>
  );
};

export default withDatasourceCheck()<RbAConsultRequestProps>(RbAConsultRequest);
