import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

export const CallTrackingGraphQLQueries = {
  campaignLandingPageByIdQuery: `query GetItem($campaignLandingPageId: String, $language: String!) {
    # path can be an item tree path or GUID-based id
    item(path: $campaignLandingPageId, language: $language) {
      id
      name
      parent {
        id
      }
      template {
        id
      }
			... on CampaignLandingPage {
				callTrackingNumber {
					value
				}
      }
    }
  }`,
  pageLevelOverrideByIdQuery: `
    query pageLevelOverrideByIdQuery($siteName: String!, $language: String!, $itemPath: String!) {
      layout(site: $siteName, routePath: $itemPath, language: $language) {
        item {
          id
          name
          parent {
            id
          }
          template {
            id
          }
          callTrackingPageLevelOverride: field(name: "callTrackingPageLevelOverride") {
            ... on TextField {
                value
            }
          }
        }
      }
    }
  `,
};

export type CampaignLandingPageGraphQlResult = {
  item: {
    callTrackingNumber: Field<string>;
  };
};

export type PageLevelOverrideGraphQlResult = {
  layout: {
    item: {
      callTrackingPageLevelOverride: Field<string>;
    };
  };
};

export type CampaignLandingPageResult = {
  callTrackingNumber: string;
};

export type PageLevelOverrideResult = {
  callTrackingPageLevelOverride: string;
};

export const CallTrackingConstants = {
  showroomPhoneNumberClass: 'field-locationphonenumber',
};
