import { ThemeFile } from 'lib/context/ThemeContext';

//export const TwoColumnHeadlineTheme = (cta2Style: string): ThemeFile => {
export const TwoColumnHeadlineTheme: ThemeFile = {
  //return {
  aw: {
    classes: {
      headlineClass: 'text-sm-m lg:text-m font-heavy mb-s',
      subheadlineClass: 'text-sm-s lg:text-s font-medium mb-s',
      bodyClass: 'text-body text-dark-gray font-regular mb-s',
      buttonGroupClass: {
        wrapper: '', //'flex-col',
        cta1Classes: '', //'mr-2 mb-m md:mb-0',
        cta2Classes: '',
        // cta2Style === 'link' || cta2Style === 'link-right-icon'
        //   ? 'ml-xs md:ml-0 px-s md:px-0'
        //   : '',
      },
    },
  },
  rba: {
    classes: {
      headlineClass: 'text-sm-m lg:text-l font-medium',
      subheadlineClass: 'text-sm-m lg:text-l font-extra-light',
      bodyClass: 'text-body text-dark-gray font-regular mb-xxs',
      buttonGroupClass: {
        wrapper: '', //'flex-col md:items-center',
        cta1Classes: '', //'mr-2',
        cta2Classes: '', //'my-s md:my-0',
      },
    },
  },
};
//};
