import React, { useEffect, useRef } from 'react';
import { Item } from '@sitecore-jss/sitecore-jss-nextjs';
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { SitecoreIds } from 'lib/constants';
import FacebookWrapper from './FacebookWrapper';
import VimeoWrapper, { getStaticProps as getVimeoStaticProps } from './VimeoWrapper';
import YoutubeWrapper from './YouTubeWrapper';
import { ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { guidEquals } from 'lib/utils/string-utils';
import { VideoStaticProps } from '.';
import { playStopVideo } from './VideoUtils';
import { useTheme } from 'lib/context/ThemeContext';

type VideoItemType =
  | Item
  | Feature.EnterpriseWeb.Enterprise.Elements.Media.VimeoVideo
  | Feature.EnterpriseWeb.Enterprise.Elements.Media.YouTubeVideo
  | Feature.EnterpriseWeb.Enterprise.Elements.Media.FacebookVideo;

type VideoWrapperProps = {
  videoItem?: VideoItemType;
  videoThumbnailImage?: ImageField;
  includeSEOSchemaForVimeoYouTube?: boolean;
  staticProps?: VideoStaticProps;
};

const VideoWrapper = ({
  videoItem,
  videoThumbnailImage,
  includeSEOSchemaForVimeoYouTube,
  staticProps,
}: VideoWrapperProps): JSX.Element => {
  const { themeName } = useTheme();
  const scrollOutRef = useRef<boolean>(false);
  const videoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // eslint-disable-next-line curly
    if (themeName !== 'rba') return;

    const currentVideoRef = videoRef.current;

    if (videoItem) {
      const { videoId } = (
        videoItem as Feature.EnterpriseWeb.Enterprise.Elements.Media.YouTubeVideo
      ).fields;

      const pauseVideo = () => {
        const videoType = guidEquals(
          videoItem.templateId,
          SitecoreIds.Feature.Data.Elements.Videos.VimeoVideo.TemplateId
        )
          ? 'vimeo'
          : 'youtube';
        playStopVideo(videoId.value, 'pauseVideo', videoType);
      };

      window.addEventListener('scroll', (e) => {
        const rect = currentVideoRef?.getBoundingClientRect();
        const isVisible = rect && rect.bottom > 0 && rect.top < window.innerHeight;

        //if video is not in view and we are scrolling out of video (to help minimize callbacks)
        if (!isVisible && scrollOutRef.current) {
          e.stopPropagation();
          pauseVideo();
          scrollOutRef.current = false;
        } else if (isVisible) {
          scrollOutRef.current = true;
        }
      });

      document.addEventListener('sliderChange', (e) => {
        const rect = currentVideoRef?.getBoundingClientRect();
        const isXVisible = rect && rect.left < 0 && rect.right < 0;

        if (!isXVisible) {
          e.stopPropagation();
          pauseVideo();
        }
      });
      return () => {
        if (currentVideoRef) {
          window.removeEventListener('scroll', () => {
            pauseVideo;
          });
          document.removeEventListener('sliderChange', () => {
            pauseVideo;
          });
        }
      };
    }
    //needed the empty return since we only want the observer if there's a video
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [videoItem, themeName]);

  if (!videoItem) {
    return <></>;
  }

  if (
    guidEquals(
      videoItem.templateId,
      SitecoreIds.Feature.Data.Elements.Videos.YouTubeVideo.TemplateId
    )
  ) {
    const videoProps = videoItem as Feature.EnterpriseWeb.Enterprise.Elements.Media.YouTubeVideo;
    return (
      <div ref={videoRef}>
        <YoutubeWrapper
          {...videoProps}
          videoThumbnailImage={videoThumbnailImage}
          includeSEOSchemaForVimeoYouTube={includeSEOSchemaForVimeoYouTube}
        />
      </div>
    );
  }

  if (
    guidEquals(
      videoItem.templateId,
      SitecoreIds.Feature.Data.Elements.Videos.FacebookVideo.TemplateId
    )
  ) {
    const videoProps = videoItem as Feature.EnterpriseWeb.Enterprise.Elements.Media.FacebookVideo;
    return <FacebookWrapper {...videoProps} />;
  }

  if (
    guidEquals(videoItem.templateId, SitecoreIds.Feature.Data.Elements.Videos.VimeoVideo.TemplateId)
  ) {
    const videoProps = videoItem as Feature.EnterpriseWeb.Enterprise.Elements.Media.VimeoVideo;
    return (
      <div ref={videoRef}>
        <VimeoWrapper
          {...videoProps}
          videoThumbnailImage={videoThumbnailImage}
          includeSEOSchemaForVimeoYouTube={includeSEOSchemaForVimeoYouTube}
          staticProps={staticProps?.vimeoStaticProps}
        />
      </div>
    );
  }

  return <div>Unrecognized Video Type: {videoItem.templateName}</div>;
};

export const getStaticProps = async (props: VideoItemType): Promise<VideoStaticProps> => {
  const result: VideoStaticProps = {};

  if (
    guidEquals(props.templateId, SitecoreIds.Feature.Data.Elements.Videos.VimeoVideo.TemplateId)
  ) {
    result.vimeoStaticProps = await getVimeoStaticProps(
      props as Feature.EnterpriseWeb.Enterprise.Elements.Media.VimeoVideo
    );
  }

  return result;
};

export default VideoWrapper;
