// we can ignore the any type warning for formData or payload value
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseSubmitAction, BaseSubmitProps, ExecutionResult } from '../../BaseSubmitAction';
import { fetchInternalAPIWithQueryString } from 'lib/utils/api-request-utils';
import { getFieldData } from 'lib/forms/FormFieldUtils';
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { FormsConstants } from 'lib/constants/forms-constants';

type SubmitRbaLeadFields =
  Feature.EnterpriseWeb.RenewalByAndersen.Forms.SubmitActions.SubmitRbALead['fields'];

export class SubmitRbaLead extends BaseSubmitAction {
  constructor(params: BaseSubmitProps) {
    super(params);
  }

  override async executeAction(): Promise<ExecutionResult> {
    const submitActionFields = this.actionFieldsProps as SubmitRbaLeadFields;
    const formType =
      submitActionFields?.formType as unknown as Feature.EnterpriseWeb.RenewalByAndersen.Forms.FormType;
    this.formData[FormsConstants.RBA.FieldNames.FormType] =
      formType?.fields?.value?.value || FormsConstants.RBA.FormTypes.ConsultationRequest;
    this.formData[FormsConstants.RBA.FieldNames.Sender] = submitActionFields?.sender?.value;
    const payload: string = JSON.stringify(getFieldData(this.formProps, this.formData));

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: payload,
    };

    const response = await fetchInternalAPIWithQueryString(
      `/api/rba/submit-actions/submit-rba-lead/`,
      requestOptions
    );

    if (response.status === 200) {
      super.setActionCookie(true);
    }

    return new Promise<ExecutionResult>((resolve) => {
      resolve({
        success: response.status === 200,
        errorMessage: this.actionFieldsProps?.errorMessage?.value,
      });
    });
  }
}
