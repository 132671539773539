import { GraphQLClient, GraphQLRequestClient } from '@sitecore-jss/sitecore-jss';
import { CacheClient, CacheOptions, MemoryCacheClient } from 'lib/jss21.2.1/cache-client';
import { JobStateAndCityData, MappedJobLocationData, StateTargetItem } from './jobLocation.Types';
import { Debug, SitecoreIds } from 'lib/constants';
import { SearchQueryService } from 'lib/graphql';
import config from 'temp/config';

export type GraphQLJobLocationDataServiceConfig = CacheOptions & {
  /**
   * Your Graphql endpoint
   */
  endpoint: string;
  /**
   * The API key to use for authentication
   */
  apiKey: string;
  /**
   * Override fetch method. Uses 'GraphQLRequestClient' default otherwise.
   */
  fetch?: typeof fetch;
};

class GraphQLJobLocationDataService {
  private graphQLClient: GraphQLClient;
  private searchService: SearchQueryService<JobStateAndCityData | StateTargetItem>;
  private cache: CacheClient<MappedJobLocationData[]>;
  private cacheKey: string;

  protected get defaultStateQuery(): string {
    return /* GraphQL */ `query getDefaultStates($language: String) {
  search(
    first: 100
    where: {
      AND: [
        {
          name: "_path"
          value: "${SitecoreIds.Content.AndersenCorporation.RenewalByAndersen.Global.DataSources.Geography.States.ItemId}"
          operator: CONTAINS
        }
        {
          OR: [
            {
              name: "_templates"
              value: "${SitecoreIds.Feature.Data.Geography.Province.TemplateId}"
              operator: EQ
            }
            {
              name: "_templates"
              value: "${SitecoreIds.Feature.Data.Geography.State.TemplateId}"
              operator: EQ
            }
          ]
        }
        { name: "_language", value: $language }
      ]
    }
    orderBy:{
      name: "fullName",
      direction:ASC
    }
  ) {
    pageInfo {
      endCursor
      hasNext
    }
    results {
      ... on State{
        fullName {
          value
        }
        abbreviation{
          value
        }
      }
       ... on Province{
        fullName {
          value
        }
        abbreviation{
          value
        }
      }
    }
  }
}`;
  }

  protected get jobStateAndCityQuery(): string {
    return /* GraphQL */ `query getJobStateAndCity($after: String, $language: String) {
  search(
    where: {
      AND: [
        {
          name: "_path"
          value: "${SitecoreIds.Content.AndersenCorporation.RenewalByAndersen.Global.Affiliates.ItemId}"
          operator: CONTAINS
        }
        {
          name: "_templates"
          value: "${SitecoreIds.Feature.Data.Affiliates.Affiliate.TemplateId}"
          operator: CONTAINS
        }
        { name: "_language", value: $language }
      ]
    }
    first: 50
    after: $after
     orderBy: {
      name:"JobCity"
      direction: ASC
    }
  ) {
    total
    pageInfo {
      endCursor
      hasNext
    }
    results {
      ... on Affiliate {
        affiliateId {
          value
        }
        name
        state {
          targetItem {
            ... on State {
              fullName {
                value
              }
              abbreviation {
                value
              }
            }
            ... on Province {
              fullName {
                value
              }
              abbreviation {
                value
              }
            }
          }
        }
        city {
          value
        }
        jobs: children(
          includeTemplateIDs: ["${SitecoreIds.Feature.RenewalByAndersen.Data.Affiliates.Datasources.JobsFolder.TemplateId}"]
        ) {
          results {
            hasChildren
            children(
              includeTemplateIDs: ["${SitecoreIds.Feature.RenewalByAndersen.Data.Affiliates.Datasources.Job.TemplateId}"]
            ) {
              results {
                ... on Job {
                  id
                  jobTitle {
                    value
                  }
                  jobState {
                    targetItem {
                      ... on State {
                        fullName {
                          value
                        }
                        abbreviation {
                          value
                        }
                      }
                      ... on Province {
                        fullName {
                          value
                        }
                        abbreviation {
                          value
                        }
                      }
                    }
                  }
                  jobCity {
                    value
                  }
                  excludeFromSearch {
                    boolValue
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
  }

  /**
   * Fetch JobLocation data using the Sitecore GraphQL endpoint.
   * @param {GraphQLJobLocationDataServiceConfig} config
   */
  constructor(protected config: GraphQLJobLocationDataServiceConfig) {
    this.graphQLClient = this.getGraphQLClient();
    this.searchService = new SearchQueryService<JobStateAndCityData | StateTargetItem>(
      this.graphQLClient
    );
    this.cache = this.getCacheClient();
    this.cacheKey = this.getCacheKey();
  }

  /**
   * Get page variant information for a route
   * @param {string} language language
   * @returns {Promise<MappedJobLocationData[] | undefined>} the applied page variants or undefined (if itemPath / language not found)
   */
  async getJobLocationData(language = 'en'): Promise<MappedJobLocationData[] | undefined> {
    let data = this.cache.getCacheValue(this.cacheKey);

    if (data && data.length > 0) {
      return data;
    } else {
      const jobStateAndCityResults = (await this.searchService.fetchAllResults(
        this.jobStateAndCityQuery,
        {
          language,
        }
      )) as JobStateAndCityData[];

      const defaultStatesData = (await this.searchService.fetchAllResults(this.defaultStateQuery, {
        language,
      })) as StateTargetItem[];

      data = this.getJobLocationMapping(jobStateAndCityResults, defaultStatesData);
      if (data && data.length > 0) {
        this.cache.setCacheValue(this.cacheKey, data);
        return data;
      }
      return undefined;
    }
  }

  resetJobLocationDataCache() {
    return this.cache.setCacheValue(this.cacheKey, []);
  }

  /**
   * Gets cache client implementation
   * Override this method if custom cache needs to be used
   * @returns CacheClient instance
   */
  protected getCacheClient(): CacheClient<MappedJobLocationData[]> {
    return new MemoryCacheClient<MappedJobLocationData[]>({
      cacheEnabled: this.config.cacheEnabled ?? true,
      cacheTimeout: this.config.cacheTimeout,
    });
  }

  protected getCacheKey() {
    return 'JobLocationData';
  }

  /**
   * Gets a GraphQL client that can make requests to the API. Uses graphql-request as the default
   * library for fetching graphql data (@see GraphQLRequestClient). Override this method if you
   * want to use something else.
   * @returns {GraphQLClient} implementation
   */
  protected getGraphQLClient(): GraphQLClient {
    return new GraphQLRequestClient(this.config.endpoint, {
      apiKey: this.config.apiKey,
      debugger: Debug.jobLocationData,
      fetch: this.config.fetch ?? fetch,
      retries:
        (process.env.GRAPH_QL_SERVICE_RETRIES &&
          parseInt(process.env.GRAPH_QL_SERVICE_RETRIES, 10)) ||
        5,
    });
  }

  /**
   * get mapping of the jobAndCity data with default state data
   * @param {JobStateAndCityData[]} jobsData job state and city data
   * @param {StateTargetItem[]} defaultState default state target list
   * @returns {MappedJobLocationData[]} the mapped jobLocationData.
   */
  protected getJobLocationMapping(
    jobsData: JobStateAndCityData[],
    defaultState: StateTargetItem[]
  ): MappedJobLocationData[] | null {
    if (!jobsData || !defaultState) {
      return null;
    }

    const stateMap = new Map<string, MappedJobLocationData>();

    // Initialize stateMap with defaultState
    defaultState.forEach((state) => {
      stateMap.set(state.fullName.value, { ...state, city: [] });
    });

    // Process each jobData
    jobsData.forEach((jobData) => {
      const { state, city, jobs, affiliateId } = jobData;

      let stateFullName = state?.targetItem?.fullName?.value || '';
      let cityValue = city?.value;

      // Skip if no jobs are available and no parent job state is available
      if (!stateFullName && !jobs?.results[0]?.hasChildren) {
        return;
      }

      // Consolidated function to add city to state
      const addCityToState = (stateFullName: string, cityValue: string) => {
        const existingState = stateMap.get(stateFullName);
        if (
          existingState &&
          !existingState.city?.some((item) => item.jobCity.value === cityValue.trim())
        ) {
          existingState.city?.push({ jobCity: { value: cityValue }, affiliateId });
        }
      };

      // If no child jobs match, add parent state and city directly
      if (
        !jobs?.results[0]?.hasChildren ||
        !jobs?.results[0]?.children?.results?.some((result) => !result.excludeFromSearch.boolValue)
      ) {
        addCityToState(stateFullName, cityValue);
        return;
      }

      // Iterate through job results
      jobs?.results[0]?.children?.results?.forEach((result) => {
        // Skip if job is excluded from search
        if (result.excludeFromSearch.boolValue) {
          return;
        }

        stateFullName = result.jobState?.targetItem?.fullName?.value || stateFullName;
        cityValue = result.jobCity?.value || cityValue;

        // Update city for the corresponding state
        addCityToState(stateFullName, cityValue);

        // Update state data from result
        const existingState = stateMap.get(stateFullName);
        if (existingState) {
          stateMap.set(stateFullName, { ...existingState, ...result.jobState?.targetItem });
        }
      });
    });

    return Array.from(stateMap.values());
  }
}

export const graphQLJobLocationDataService = new GraphQLJobLocationDataService({
  endpoint: config.graphQLEndpoint,
  apiKey: config.sitecoreApiKey,
  cacheEnabled: true,
  cacheTimeout: 600,
  // NOTE: we provide native fetch for compatibility on Next.js Edge Runtime
  // (underlying default 'cross-fetch' is not currently compatible: https://github.com/lquixada/cross-fetch/issues/78)
  fetch: fetch,
});
