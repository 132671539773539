// Global
import React from 'react';
import Head from 'next/head';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import config from 'temp/config';
// Components
import { ComponentProps } from 'lib/types/component-props';
import { useExperienceEditor } from 'lib/utils';
import { Field } from 'lib/jss21.2.1/layout';
import Component from 'src/helpers/Component/Component';
import ImageWrapper from 'src/helpers/Media/ImageWrapper';
import { EwSiteInfo } from 'lib/site/ew-site-info';
import { CallTrackingConstants } from 'lib/constants/call-tracking';
import { getShowroom } from 'lib/utils/showroom-utils';

const ShowroomAddressAndHours = (props: ComponentProps): JSX.Element => {
  const context = useSitecoreContext();
  const showroomData = getShowroom({ context });
  const isEE = useExperienceEditor();

  const siteList = JSON.parse(config.sites) as EwSiteInfo[];

  const contextSiteInfo = siteList.filter(
    (item) => item.name === context.sitecoreContext.site?.name
  );

  if (!showroomData.showroom) {
    if (isEE) {
      return <div>Not on a Showroom page, or no showroom selected for page</div>;
    }
    return <></>;
  }

  const hoursParams = new URLSearchParams(showroomData.showroom?.fields?.hours.value);

  // schema.org
  const ldJsonScript = {
    '@context': 'https://schema.org',
    '@type': 'HomeAndConstructionBusiness',
    '@id':
      (contextSiteInfo[0]?.canonicalHostName &&
        contextSiteInfo[0]?.canonicalHostName + showroomData.showroomPageUrl) ||
      '',
    address: {
      '@type': 'PostalAddress',
      addressLocality: showroomData.showroom?.fields?.showroomName?.value || '',
      addressRegion:
        (showroomData.showroom?.fields?.state?.fields?.Abbreviation as Field<string>)?.value || '',
      postalCode: showroomData.showroom?.fields?.postalCode?.value || '',
      streetAddress: showroomData.showroom?.fields?.addressLine1?.value || '',
    },
    name:
      (showroomData.showroom?.fields?.affiliates &&
        (
          showroomData.showroom?.fields?.affiliates.map(
            (e) => (e.fields?.affiliateBusinessName as Field<string>)?.value
          ) ?? []
        ).join(', ')) ||
      '',
    image: showroomData.showroom.fields?.showroomImage?.value?.src || '',
    openingHours:
      showroomData.showroom?.fields?.hours.value
        ?.replaceAll?.('%3A', ':')
        ?.replaceAll?.('%20', ' ') || '',
    telephone: showroomData.showroom.fields?.locationPhoneNumber.value || '',
    url:
      (contextSiteInfo[0]?.canonicalHostName &&
        contextSiteInfo[0]?.canonicalHostName + showroomData.showroomPageUrl) ||
      '',
  };

  return (
    <Component
      variant="lg"
      dataComponent="affiliate/showroom-address-and-hours"
      gap="gap-y-m md:gap-s"
      {...props}
      rendering={{ dataSource: 'StaticDataSource' }}
    >
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJsonScript) }}
        />
      </Head>
      <div className="col-span-12 md:col-span-4 md:col-start-2">
        <h2 className="mb-s font-sans text-sm-s font-medium md:text-m">Address and hours</h2>
        <div
          className={`mb-m text-sm-xs font-bold text-dark-gray ${CallTrackingConstants.showroomPhoneNumberClass}`}
        >
          <p className="">
            {showroomData.showroom?.fields?.addressLine1?.value || ''}
            {showroomData.showroom?.fields?.addressLine2?.value || ''}
          </p>
          <p className="mb-s">
            {showroomData.showroom?.fields?.city?.value},{' '}
            {showroomData.showroom?.fields?.state?.name},{' '}
            {showroomData.showroom?.fields?.postalCode?.value}
          </p>
          <a
            href={`tel:${showroomData.showroom.fields?.locationPhoneNumber.value}`}
            className={CallTrackingConstants.showroomPhoneNumberClass}
          >
            {showroomData.showroom.fields?.locationPhoneNumber.value}
          </a>
        </div>
        <h3 className="text-sm-xxs font-bold">Showroom hours</h3>
        <ul className="text-body text-dark-gray">
          {[...hoursParams.keys()].map((key) => (
            <li key={key}>
              {key}: {hoursParams.get(key)}
            </li>
          ))}
        </ul>
      </div>
      {showroomData.showroom.fields?.showroomImage?.value?.src && (
        <div className="col-span-12 md:col-span-7">
          <ImageWrapper
            image={showroomData.showroom.fields?.showroomImage}
            imageLayout="intrinsic"
          />
        </div>
      )}
    </Component>
  );
};

export default ShowroomAddressAndHours;
