import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import React from 'react';
import classNames from 'classnames';
import { getEnum } from 'lib/utils';
import { FormFieldProps } from 'lib/forms';
import FieldWrapper, { FieldWrapperProps } from '../Structure/FieldWrapper/FieldWrapper';
import { useTheme } from 'lib/context/ThemeContext';
import { FormFieldsTheme } from './FormFields.Theme';

type Alignment = 'left' | 'center' | 'right' | '';

export type LabelTooltipProps = Feature.EnterpriseWeb.Enterprise.Forms.Elements.LabelTooltip &
  FormFieldProps;

const LabelTooltip = (props: LabelTooltipProps) => {
  const { fields } = props;
  const alignment = getEnum<Alignment>(props.fields?.alignment);
  const { themeName } = useTheme(FormFieldsTheme);

  let alignmentClasses = '';
  let displayStyle = '';

  if (themeName === 'aw') {
    displayStyle = 'flex';
  }

  if (alignment === 'center') {
    alignmentClasses = 'text-center justify-center';
  }
  if (alignment === 'left') {
    alignmentClasses = 'text-left justify-left';
  }
  if (alignment === 'right') {
    alignmentClasses = 'text-right justify-end';
  }

  if (!fields) {
    return <></>;
  }

  return (
    <div className={classNames(alignmentClasses, displayStyle, ' col-span-12')}>
      <FieldWrapper {...(props as FieldWrapperProps)} />
    </div>
  );
};

export default LabelTooltip;
