import classNames from 'classnames';
import { ThemeFile, ThemeName, useTheme } from 'lib/context/ThemeContext';
import { useEffect, useRef, useState } from 'react';
import { FormFieldsTheme } from '../FormFields.Theme';
import SvgIcon from 'src/helpers/SvgIcon/SvgIcon';
import {
  FormikContextType,
  FormikErrors,
  FormikTouched,
  FormikValues,
  useFormikContext,
} from 'formik';
import { MappedJobLocationData } from 'lib/forms/JobLocationDropdown/jobLocation.Types';
import { BodyCopy } from 'src/helpers/BodyCopy';
import { Field } from 'lib/jss21.2.1/layout';

type JobCityCheckboxProps = {
  themeData: ThemeFile[ThemeName];
  label: string;
  id: string;
  value: string;
  name: string;
  affiliateId: string;
};

type LocationValueItemType = {
  location: string;
  affiliateId: string;
};

const JobCityCheckbox = ({
  themeData,
  label,
  id,
  value,
  name,
  affiliateId,
}: JobCityCheckboxProps) => {
  const { values, setFieldValue } = useFormikContext() as FormikContextType<FormikValues>;

  const [isChecked, setIsChecked] = useState(
    values[name].some((item: LocationValueItemType) => item.location === id)
  );

  const handleCheckboxChange = () => {
    const prevValues: LocationValueItemType[] = values[name] || [];

    if (!isChecked) {
      prevValues?.push({ location: id, affiliateId: affiliateId });
      setFieldValue(name, prevValues);
    } else {
      const filteredValues = prevValues.filter((item) => item.location !== id);
      setFieldValue(name, filteredValues);
    }
    setIsChecked(!isChecked);
  };

  return (
    <>
      <input
        className={classNames(themeData.classes.checkbox.input)}
        onChange={() => handleCheckboxChange()}
        type="checkbox"
        value={value}
        id={id}
        name={name}
        checked={isChecked}
      />
      {/* custom checkbox icon */}
      <svg
        role="img"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        className={themeData.classes.checkbox.customTickIcon}
      >
        <title>checkbox</title>
        <g clipPath="url(#clip0_4834_6396)">
          <path
            d="M7.50013 13.5454L4.02513 10.0704L2.8418 11.2454L7.50013 15.9038L17.5001 5.90376L16.3251 4.72876L7.50013 13.5454Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_4834_6396">
            <rect width="20" height="20" fill="currentColor" transform="translate(0 0.0703125)" />
          </clipPath>
        </defs>
      </svg>
      <label className={classNames(themeData.classes.label, 'cursor-pointer')} htmlFor={id}>
        {label}
      </label>
    </>
  );
};

type JobLocationSelectorProps = {
  name: string;
  placeholderText: Field<string>;
  options?: MappedJobLocationData[] | null;
};

export const JobLocationSelector = ({
  name,
  options,
  placeholderText,
}: JobLocationSelectorProps) => {
  const { values, touched, errors, validateField, setFieldTouched, setFieldValue } =
    useFormikContext() as FormikContextType<FormikValues>;

  const NO_JOB_CITY_TEXT = "I'm sorry. There are no available jobs at this time.";

  const isInvalid =
    (touched as FormikTouched<FormikValues>)[name] && (errors as FormikErrors<FormikValues>)[name];

  const transformSelectValues = () => {
    return values[name].map((item: LocationValueItemType) => item.location).join(';');
  };

  const [isOpen, setIsOpen] = useState(false);

  const { themeData } = useTheme(FormFieldsTheme);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    isOpen && setFieldTouched(name, true, true);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleBlur = (event: MouseEvent) => {
      const dropdown = dropdownRef.current;
      const isClickInside = dropdown && dropdown.contains(event.relatedTarget as Node);

      // Check if the click occurred inside the dropdown or its children
      if (!isClickInside) {
        const isClickInsideDropdownDrawer = dropdown && dropdown.contains(event.target as Node);
        if (!isClickInsideDropdownDrawer) {
          setIsOpen((prevValue) => {
            if (prevValue) {
              setFieldTouched(name, true, true);
              setFieldValue(name, values[name]);
            }
            return false;
          });
        }
      }
    };
    document.addEventListener('mousedown', handleBlur);

    return () => {
      document.removeEventListener('mousedown', handleBlur);
    };
  }, [name, validateField, isOpen, setFieldTouched]);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        type="button"
        className={classNames(
          'relative w-full bg-white pr-[38px] ',
          themeData.classes.input,
          isInvalid ? themeData.classes.errorOutline : '',
          values[name] ? 'border-black' : ''
        )}
        onClick={handleToggle}
        aria-expanded={isOpen}
        aria-haspopup="true"
      >
        {values[name].length > 0 ? (
          <span className="overflow-hidden whitespace-nowrap text-body text-theme-body !text-black">
            {transformSelectValues()}
          </span>
        ) : (
          <BodyCopy
            classes="text-theme-body text-body"
            fields={{
              body: placeholderText,
            }}
          />
        )}

        <div className={`absolute right-xs top-1/2 -translate-y-1/2 `}>
          <SvgIcon icon="dropdown-arrow" />
        </div>
      </button>
      <div
        className={classNames(
          'absolute left-0 z-[10] max-h-[338px] w-full overflow-y-auto  border border-t-0  bg-white px-xxs',
          { hidden: !isOpen }
        )}
        role="menu"
      >
        {options?.map((option) => {
          const stateFullName = option?.fullName?.value;
          const stateAbbreviation = option?.abbreviation?.value;

          return (
            <details className="group mb-xxxs border-b last:border-b-0 " key={stateFullName}>
              <summary className="my-xs flex  cursor-pointer list-none items-center gap-x-xxs [&::-webkit-details-marker]:hidden">
                <SvgIcon icon="plus" className=" group-open:hidden" />
                <SvgIcon icon="minus" className="hidden group-open:block" />
                <span className={classNames(themeData.classes.bodyText, 'text-dark-gray')}>
                  {stateFullName}
                </span>
              </summary>
              <dl className="ml-[1.25rem]">
                {option?.city?.length !== 0 ? (
                  option.city
                    ?.slice()
                    .sort((a, b) => a.jobCity.value.localeCompare(b.jobCity.value))
                    ?.map((job) => {
                      const jobCity = job.jobCity?.value;
                      const affiliateId = job.affiliateId?.value;

                      return (
                        <div key={jobCity} className="my-xs">
                          <dd className="flex w-full items-center gap-xxs">
                            <JobCityCheckbox
                              key={jobCity}
                              name={name}
                              id={`${jobCity}, ${stateAbbreviation}`}
                              themeData={themeData}
                              value={jobCity}
                              label={jobCity}
                              affiliateId={affiliateId}
                            />
                          </dd>
                        </div>
                      );
                    })
                ) : (
                  <BodyCopy
                    classes="my-xxs text-theme-body text-body"
                    fields={{
                      body: {
                        value: NO_JOB_CITY_TEXT,
                      },
                    }}
                  />
                )}
              </dl>
            </details>
          );
        })}
      </div>
    </div>
  );
};
