import React, { useContext, useEffect, useRef } from 'react';
import { FormFieldProps } from 'lib/forms';
import {
  useOnlineScheduling,
  useOnlineSchedulingDispatch,
} from 'lib/forms/OnlineScheduling/OnlineSchedulingContext';
import { FormikValues, useFormikContext } from 'formik';
import { formActionFactory } from 'temp/formActionFactory';
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { FormsContext } from 'lib/forms/FormContext';
import { Foundation } from 'src/.generated/Foundation.EnterpriseWeb.model';
import { OnlineSchedulingConstants } from 'lib/constants/online-scheduling';
import TagManager from 'react-gtm-module';

export type OSTimeoutProps =
  Feature.EnterpriseWeb.RenewalByAndersen.Forms.Events.OnlineSchedulingTimeout & FormFieldProps;

const OnlineSchedulingTimeout = (props: OSTimeoutProps): JSX.Element => {
  const onlineSchedulingDispatch = useOnlineSchedulingDispatch();
  const onlineSchedulingContext = useOnlineScheduling();

  const elementRef = useRef<HTMLElement>(null);

  const { formProps, updatePageIndex, isErrorOnSubmit, setIsErrorOnSubmit } =
    useContext(FormsContext);

  const { values } = useFormikContext<FormikValues>();

  function trimStringValues(formikValues: FormikValues) {
    // Iterate through the object properties
    for (const key in formikValues) {
      // Check if the property value is a string
      if (typeof formikValues[key] === 'string') {
        // Trim the string value
        formikValues[key] = formikValues[key].trim();
      }
    }
    // Return the updated object with trimmed string values
    return formikValues;
  }

  const submitActionNextStep = useRef(-1);
  useEffect(() => {
    const executeActions = async () => {
      const submitActions = props.fields?.children;

      //Reset error upon submitting the form
      if (isErrorOnSubmit) {
        setIsErrorOnSubmit(false);
      }

      if (submitActions?.length > 0) {
        // Logic here to execute actions if available

        for (let i = 0; i < submitActions.length; i++) {
          const action: FormFieldProps = submitActions[i];

          const actionHandler = formActionFactory(
            action,
            trimStringValues(values),
            formProps,
            props,
            onlineSchedulingContext
          );
          const result = await actionHandler?.executeAction();
          if (!!result?.result) {
            onlineSchedulingDispatch(result.result); // after executing the submit action, dispatch an event to the onineSchedulingReducer to update the OnlineSchedulingContext
            result.nextPageIndex && (submitActionNextStep.current = result.nextPageIndex);
          }

          if (
            !result?.success &&
            (action as Foundation.EnterpriseWeb.Enterprise.BaseTemplates.BaseSubmitAction).fields
              ?.stopOnError.value
          ) {
            setIsErrorOnSubmit(result?.errorMessage as string);
            throw 'error';
          }
        }

        // Execute callback function if exists after all the submit actions are executed
        formProps?.callbackAfterSubmit &&
          (formProps?.callbackAfterSubmit as (...args: Array<unknown>) => unknown)();
      }
    };

    const submitForm = async () => {
      setIsErrorOnSubmit(false);
      // Execute actions if available
      try {
        await executeActions(); // Execute actions and handle any errors
        // Actions executed successfully, advance to the action next step
        updatePageIndex(submitActionNextStep.current);
      } catch (actionError) {}
    };

    const handleTimeout = async () => {
      await submitForm();
    };

    const handleCloseModal = async () => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'abandon_form',
          form_name: 'Online Scheduling',
          form_submit_text: 'Close modal',
        },
      });
      await submitForm();
    };

    document.addEventListener(OnlineSchedulingConstants.events.closeModal, handleCloseModal);

    if (
      !isNaN(parseInt(props.fields?.timeout?.value)) &&
      parseInt(props.fields?.timeout?.value) > 0
    ) {
      let timer = setTimeout(() => handleTimeout(), parseInt(props.fields?.timeout?.value));

      // So long as the user clicks on something (without it or its parents calling stopPropagation), reset timer
      document.addEventListener('click', resetTimer);

      function resetTimer() {
        clearTimeout(timer);
        timer = setTimeout(() => handleTimeout(), parseInt(props.fields?.timeout?.value));
      }

      return () => {
        clearTimeout(timer);
        document.removeEventListener('click', resetTimer);
        document.removeEventListener(OnlineSchedulingConstants.events.closeModal, handleCloseModal);
      };
    }
    return () => {
      document.removeEventListener(OnlineSchedulingConstants.events.closeModal, handleCloseModal);
    };
  }, [
    formProps,
    isErrorOnSubmit,
    onlineSchedulingContext,
    onlineSchedulingDispatch,
    props,
    setIsErrorOnSubmit,
    submitActionNextStep,
    updatePageIndex,
    values,
  ]);

  return <span ref={elementRef}></span>;
};

export default OnlineSchedulingTimeout;
