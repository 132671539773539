export const OPTIONS: {
  [key: string]: {
    [key: string]: string;
  };
} = {
  panelStyles: {
    contemporary_cap: 'Contemporary Aluminum',
    contemporary_ccp: 'Contemporary Aluminum Clad Wood',
    traditional: 'Traditional Aluminum Clad Wood',
  },
  stackingDirection: {
    one_direction: '1-Way Left',
    one_direction_right: '1-Way Right',
    two_direction: '2-Way',
  },
  calculateUsing: {
    rough_opening: 'Rough Opening',
    rough_opening_pocket: 'Rough Opening Without Pocket',
    clear_opening: 'Clear Opening',
    unit_dimensions: 'Unit Dimensions',
  },
  insectScreens: {
    none: 'None',
    multi: 'Multi-panel',
    retractable: 'Retractable',
    single: 'Single',
  },
  screenConfigurations: {
    single: 'Single Screen',
    double: 'Double Screen',
  },
  sillOptions: {
    onfloor_drainage: 'On-Floor Drainage',
    onfloor_drainage_raised_threshold: 'On-Floor Drainage With Raised Threshold',
    flush: 'Flush',
  },
  sillRamps: {
    none: 'None',
    interior: 'Interior',
    exterior: 'Exterior',
    both: 'Both',
  },
  panelStackingLocation: {
    interior: 'Interior',
    exterior: 'Exterior',
  },
};

export const MIN_MAX_WIDTHS = [
  ['one_direction', 'pocketing', 'contemporary_cap', 1, 32.375, 64.375],
  ['one_direction', 'pocketing', 'contemporary_cap', 2, 58.25, 121.625],
  ['one_direction', 'pocketing', 'contemporary_cap', 3, 84, 178.875],
  ['one_direction', 'pocketing', 'contemporary_cap', 4, 109.875, 235.875],
  ['one_direction', 'pocketing', 'contemporary_cap', 5, 135.625, 293.375],
  ['one_direction', 'pocketing', 'contemporary_cap', 6, 161.5, 350.625],
  ['one_direction', 'pocketing', 'contemporary_ccp', 1, 32.375, 64.375],
  ['one_direction', 'pocketing', 'contemporary_ccp', 2, 58.25, 121.625],
  ['one_direction', 'pocketing', 'contemporary_ccp', 3, 84, 178.875],
  ['one_direction', 'pocketing', 'contemporary_ccp', 4, 109.875, 235.875],
  ['one_direction', 'pocketing', 'contemporary_ccp', 5, 135.625, 293.375],
  ['one_direction', 'pocketing', 'contemporary_ccp', 6, 161.5, 350.625],
  ['one_direction', 'pocketing', 'traditional', 1, 32.375, 64.375],
  ['one_direction', 'pocketing', 'traditional', 2, 56.125, 120],
  ['one_direction', 'pocketing', 'traditional', 3, 79.75, 175.625],
  ['one_direction', 'pocketing', 'traditional', 4, 103.375, 231.25],
  ['one_direction', 'pocketing', 'traditional', 5, 127, 286.875],
  ['one_direction', 'pocketing', 'traditional', 6, 150.75, 342.625],
  ['one_direction', 'stacking', 'contemporary_cap', 2, 58.125, 121.5],
  ['one_direction', 'stacking', 'contemporary_cap', 3, 83.875, 178.75],
  ['one_direction', 'stacking', 'contemporary_cap', 4, 109.75, 236],
  ['one_direction', 'stacking', 'contemporary_cap', 5, 135.5, 293.25],
  ['one_direction', 'stacking', 'contemporary_cap', 6, 161.375, 350.5],
  ['one_direction', 'stacking', 'contemporary_ccp', 2, 58.125, 121.5],
  ['one_direction', 'stacking', 'contemporary_ccp', 3, 83.875, 178.75],
  ['one_direction', 'stacking', 'contemporary_ccp', 4, 109.75, 236],
  ['one_direction', 'stacking', 'contemporary_ccp', 5, 135.5, 293.25],
  ['one_direction', 'stacking', 'contemporary_ccp', 6, 161.375, 350.5],
  ['one_direction', 'stacking', 'traditional', 2, 56, 119.875],
  ['one_direction', 'stacking', 'traditional', 3, 79.625, 175.5],
  ['one_direction', 'stacking', 'traditional', 4, 103.25, 231.125],
  ['one_direction', 'stacking', 'traditional', 5, 126.875, 286.75],
  ['one_direction', 'stacking', 'traditional', 6, 150.625, 342.5],
  ['two_direction', 'pocketing', 'contemporary_cap', 2, 60.25, 124.125],
  ['two_direction', 'pocketing', 'contemporary_cap', 4, 111.875, 238.625],
  ['two_direction', 'pocketing', 'contemporary_cap', 6, 163.5, 353.125],
  ['two_direction', 'pocketing', 'contemporary_cap', 8, 215.125, 467.625],
  ['two_direction', 'pocketing', 'contemporary_cap', 10, 266.75, 490.125],
  ['two_direction', 'pocketing', 'contemporary_cap', 12, 318.375, 503.875],
  ['two_direction', 'pocketing', 'contemporary_ccp', 2, 60.25, 124.125],
  ['two_direction', 'pocketing', 'contemporary_ccp', 4, 111.875, 238.625],
  ['two_direction', 'pocketing', 'contemporary_ccp', 6, 163.5, 353.125],
  ['two_direction', 'pocketing', 'contemporary_ccp', 8, 215.125, 467.625],
  ['two_direction', 'pocketing', 'contemporary_ccp', 10, 266.75, 490.125],
  ['two_direction', 'pocketing', 'contemporary_ccp', 12, 318.375, 503.875],
  ['two_direction', 'pocketing', 'traditional', 2, 60.25, 124.125],
  ['two_direction', 'pocketing', 'traditional', 4, 107.625, 235.5],
  ['two_direction', 'pocketing', 'traditional', 6, 154.875, 346.75],
  ['two_direction', 'pocketing', 'traditional', 8, 202.25, 458.125],
  ['two_direction', 'pocketing', 'traditional', 10, 249.5, 488],
  ['two_direction', 'pocketing', 'traditional', 12, 249.5, 501.5],
  ['two_direction', 'stacking', 'contemporary_cap', 4, 112.625, 239.375],
  ['two_direction', 'stacking', 'contemporary_cap', 6, 164.25, 353.875],
  ['two_direction', 'stacking', 'contemporary_cap', 8, 215.875, 468.375],
  ['two_direction', 'stacking', 'contemporary_cap', 10, 267.5, 582.875],
  ['two_direction', 'stacking', 'contemporary_cap', 12, 319.125, 599],
  ['two_direction', 'stacking', 'contemporary_ccp', 4, 112.625, 239.375],
  ['two_direction', 'stacking', 'contemporary_ccp', 6, 164.25, 353.875],
  ['two_direction', 'stacking', 'contemporary_ccp', 8, 215.875, 468.375],
  ['two_direction', 'stacking', 'contemporary_ccp', 10, 267.5, 582.875],
  ['two_direction', 'stacking', 'contemporary_ccp', 12, 319.125, 599],
  ['two_direction', 'stacking', 'traditional', 4, 108.375, 236.25],
  ['two_direction', 'stacking', 'traditional', 6, 155.625, 347.5],
  ['two_direction', 'stacking', 'traditional', 8, 203, 458.875],
  ['two_direction', 'stacking', 'traditional', 10, 250.25, 570.125],
  ['two_direction', 'stacking', 'traditional', 12, 297.625, 599],
];
