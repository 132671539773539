import Head from 'next/head';
import { HTMLAttributeReferrerPolicy } from 'react';

import { isValidForEnvironment } from 'lib/utils/is-valid-for-environment';
import { getEnum } from 'lib/utils';
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';

export type HeadLinkProps = Feature.EnterpriseWeb.Enterprise.Components.Advanced.HeadLink;

type CrossOrigin = '' | 'anonymous' | 'use-credentials';

const HeadLink = (props: HeadLinkProps): JSX.Element | null => {
  const { fields } = props;

  const href = fields?.href?.value;
  const rel = fields?.rel?.value;
  const title = fields?.title?.value;
  const type = fields?.type?.value;
  const media = fields?.media?.value;
  const sizes = fields?.sizes?.value;
  const hreflang = fields?.hreflang?.value;
  const crossOrigin: CrossOrigin | undefined = getEnum<CrossOrigin>(fields?.crossOrigin);
  const referrerPolicy: HTMLAttributeReferrerPolicy | undefined =
    getEnum<HTMLAttributeReferrerPolicy>(fields?.referrerPolicy);
  const charSet = fields?.charset?.value;

  //If no environment matches, or the href is missing, return null to prevent rendering.
  if (!isValidForEnvironment(props) || !href) {
    return null;
  }

  const linkAttributes = {
    href,
    rel,
    title,
    type,
    media,
    sizes,
    hreflang: hreflang,
    crossOrigin,
    referrerPolicy,
    charSet,
  };

  return (
    <Head>
      <link {...Object.fromEntries(Object.entries(linkAttributes).filter(([, value]) => value))} />
    </Head>
  );
};

export default HeadLink;
