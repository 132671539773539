// Global
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
// Components
import Component from 'src/helpers/Component/Component';
import { FormProps } from '../Form/Form';
import { OnlineSchedulingProvider } from 'lib/forms/OnlineScheduling/OnlineSchedulingContext';
import Script from 'next/script';
import OnlineSchedulingFormWrapperHelper from './OnlineSchedulingFormWrapper.helper';

export type OnlineSchedulingProps = FormProps;
const OnlineScheduling = (props: OnlineSchedulingProps) => {
  if (!props.fields) {
    return <></>;
  }

  return (
    <OnlineSchedulingProvider>
      <Script src="/iframe-resizer/iframeResizer.contentWindow.min.js" />
      <Component variant="full" dataComponent="forms/onlinescheduling" {...props}>
        <OnlineSchedulingFormWrapperHelper {...props}></OnlineSchedulingFormWrapperHelper>
      </Component>
    </OnlineSchedulingProvider>
  );
};

export default withDatasourceCheck()<OnlineSchedulingProps>(OnlineScheduling);
